import React, { Component } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { Button, List, ListItem, Avatar } from '@material-ui/core'
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';
import './newStyles.css';
import style from './styles'

class Integrations extends Component {
  render(){

    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet
        {...rest}
        className={rootClassName}>
        <PortletHeader>
          <PortletLabel
            title="Integrations"
            classes={{ subtitle: classes.subtitle }}
            subtitle="For your dashboard and webchat"
          />
        </PortletHeader>
        <PortletContent style={{ display: 'flex' }}>
          <List style={{ display: 'flex' }}>
            <ListItem>
              <Avatar
                style={{ width: 200, height: 200 }}
                className="integration_item"
                src="https://a.slack-edge.com/7f1a0/plugins/gcalendar/assets/service_512.png"/>
            </ListItem>
            <ListItem>
              <Avatar
                style={{ width: 200, height: 200 }}
                className="integration_item"
                src="https://is1-ssl.mzstatic.com/image/thumb/Purple114/v4/ba/2b/c2/ba2bc29a-cde5-b8c2-e0bf-da9e6a9f9d20/AppIcon-0-1x_U007emarketing-0-0-GLES2_U002c0-512MB-sRGB-0-0-0-85-220-0-0-0-7.jpeg/246x0w.jpg"/>
            </ListItem>
          </List>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <ListItem>Have another integration in mind?</ListItem>
          <Button
            style={{ width: '30%' }}
            variant="contained"
            color="primary"
            >
            Let us know!
          </Button>
        </PortletFooter>
      </Portlet>
    )
  }
}

export default withStyles(style)(Integrations)
