import firebase from 'firebase/app'
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';

var firebaseConfig = {
  apiKey: "AIzaSyB3x3DzUpU5-FOndzoaD9vInb9JFAQRII0",
  authDomain: "realtimechat-72615.firebaseapp.com",
  databaseURL: "https://realtimechat-72615.firebaseio.com",
  projectId: "realtimechat-72615",
  storageBucket: "realtimechat-72615.appspot.com",
  messagingSenderId: "406362753010",
  appId: "1:406362753010:web:a7cc996d124dc701"
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth()
const db = firebase.database()

const googleAuth = new firebase.auth.GoogleAuthProvider();

export { firebase, auth, db, googleAuth }
