import React, { Component } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { List, ListItem } from '@material-ui/core'
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';
import style from './styles'

class AssignChat extends Component {
  render(){

    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet
        {...rest}
        className={rootClassName}>
        <PortletHeader>
          <PortletLabel
            title="How to assign web chats"
          />
        </PortletHeader>
        <PortletContent>
          <List>
            <ListItem>-Open the chat that you would like to assign to an employee.</ListItem>
            <ListItem>-Navigate to the top bar and press the middle button.</ListItem>
            <ListItem>-Choose who you would like to assign the chat to.</ListItem>
            <ListItem>-Add a short note for the employee so they know what the conversation is about</ListItem>
            <ListItem>-The assigned employee will receive a text and can message the customer from there!</ListItem>
          </List>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          {/*<Button
            variant="contained"
            color="secondary"
            >
            Watch Video
          </Button>*/}
        </PortletFooter>
      </Portlet>
    )
  }
}

export default withStyles(style)(AssignChat)
