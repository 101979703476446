import React, { Component } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';
import style from './styles'

class Video extends Component {
  render(){

    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet
        {...rest}
        className={rootClassName}>
        <PortletHeader>
          <PortletLabel
            title="Real Time Chat Tutorial"
          />
        </PortletHeader>
        <PortletContent>
        <iframe title="video" src="https://player.vimeo.com/video/504454936" width="100%" height="480" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>

        </PortletFooter>
      </Portlet>
    )
  }
}

export default withStyles(style)(Video)
