import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  IconButton,
  Popover,
  Toolbar,
  Typography,
  Button,
  Badge
} from '@material-ui/core';

import {
  Menu as MenuIcon,
  Close as CloseIcon,
  NotificationsOutlined as NotificationsIcon,
} from '@material-ui/icons';

import { NotificationList, AdminModal } from './components';
import ReactNotifications from 'react-browser-notifications';
import { auth, db } from '../../../../database/firebase';
import moment from 'moment';
import styles from './styles';

class Topbar extends Component {

  state = {
    notifications: [],
    rooms: [],
    show: false,
    isAdmin: false,
    desktopNotifications: false,
    notificationsLimit: 4,
    notificationsCount: 0,
    notificationsEl: null,
    user: '',
    bID: ''
  };

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if(user){
        this.setState({ user: user.uid }, () => {
          if(user.uid === 'n3MgYRYOwqhcOInXoQSYDZPmBhe2'){
            this.setState({ isAdmin: true })
          }
        })
        db.ref(`users/${user.uid}/bID`)
        .once('value', (snap) => {
          let bID = localStorage.getItem('rtc_bid') || snap.val()[0]
          this.setState({ user: user.uid, bID }, () => {
            this.getNotifications();
            this.getPref()
          })
        })
      }
    })
  };

  getPref = () => {
    const { bID } = this.state;
    db.ref(`companies/${bID}/desktopNotifications`)
    .once('value', (snap) => {
      this.setState({ desktopNotifications: snap.val() })
    })
  }

  getNotifications = () => {
    const { user } = this.state;

    let notifications = [];

    db.ref(`users/${user}/notifications`)
    .once('value', (snap) => {
      snap.forEach((n) => {
        let key = n.key;
        let type = n.val().type;
        let title = n.val().title;
        let link = n.val().link;
        let when = moment(n.val().time).format('MMM Do');
        notifications.push({ key, type, title, link, when })
      })
    })
    .then(() => this.setState({ notifications }))
    .then(() => {
      this.listenForChats();
      this.getCompanies();
    })
  }

  getCompanies = () => {
    let companies = []
    db.ref('companies')
    .once('value', (snap) => {
      snap.forEach((n) => {
        let name = n.val().name;
        let bID = n.key;
        companies.push({ name, bID });
      })
    })
    .then(() => this.setState({ companies }))
  }

  listenForChats = () => {
    const { bID } = this.state;

    let chatCount = localStorage.getItem('chat');

    db.ref(`companies/${bID}/rooms`)
    .on('child_added', (snap) => {
      this.state.rooms.push(snap.val())
      this.setState({ updated: true })
      if(this.state.rooms.length > chatCount){
        this.showDesktopNotif();
        localStorage.setItem('chat', this.state.rooms.length)
      }
    })
  };

  showDesktopNotif = () => {
    const { desktopNotifications } = this.state;

    if(this.n.supported()){
      desktopNotifications && this.n.show()
      this.playSound()
    }
  }

  playSound = () => {
    let audio = document.getElementById("notif");
    audio.play()
  }

  handleSignOut = () => {
    auth.signOut()
    window.location.reload()
  };

  handleShowNotifications = event => {
    this.setState({
      notificationsEl: event.currentTarget
    });
  };

  handleCloseNotifications = () => {
    this.setState({
      notificationsEl: null
    });
  };

  handleDeleteNotification = key => e => {
    const { user } = this.state;
    db.ref(`users/${user}/notifications/${key}`)
    .remove()
    .then(() => this.getNotifications())
  }

  handleClickNotif = ({ link, key }) => {
    const { user } = this.state;
    db.ref(`users/${user}/notifications/${key}`)
    .remove()
    .then(() => window.location.pathname = link)
  }

  handleShowChatPreview = () => {
    let isShown = localStorage.getItem('isShown');
    if(isShown){
      localStorage.removeItem('isShown')
      this.setState({ show: false })
    } else {
      localStorage.setItem('isShown', true);
      this.setState({ show: true })
    }
  }

  render() {
    const {
      classes,
      className,
      title,
      isSidebarOpen,
      onToggleSidebar
    } = this.props;

    const { notifications, notificationsEl, isAdmin } = this.state

    const rootClassName = classNames(classes.root, className);
    const showNotifications = Boolean(notificationsEl);

    return (
      <Fragment>
        <audio src="https://firebasestorage.googleapis.com/v0/b/realtimechat-72615.appspot.com/o/images%2Fzapsplat_multimedia_alert_warm_piano_pads_001_39465.mp3?alt=media&amp;token=a4fd8d6f-d39c-4c9c-853e-3dfe23b99612" id="notif"></audio>
        <div className={rootClassName}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              className={classes.menuButton}
              onClick={onToggleSidebar}
              variant="text"
            >
              {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
            <Typography
              className={classes.title}
              variant="h4"
            >
              {title}
            </Typography>
            <div style={{ paddingLeft: 20 }}>
              {
                isAdmin &&
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.setState({ adminOpen: true })}
                    >
                    Switch Account
                  </Button>
              }
              <AdminModal
                setOpen={() => this.setState({ adminOpen: false })}
                open={this.state.adminOpen}
                data={this.state.companies}
                />
            </div>
            <IconButton
              className={classes.notificationsButton}
              onClick={this.handleShowNotifications}
            >
              <Badge
                badgeContent={notifications.length}
                color="primary"
                variant="dot"
              >

              </Badge>
              <NotificationsIcon />
            </IconButton>

            {/*<Tooltip title="Log out">
              <IconButton
                className={classes.signOutButton}
                onClick={this.handleSignOut}
              >
                <InputIcon />
            </IconButton>
          </Tooltip>*/}
          </Toolbar>
        </div>
        <ReactNotifications
          onRef={ref => (this.n = ref)}
          title="New Chat!"
          body="View and reply in the chat section of your RTC Dashboard"
          icon="https://firebasestorage.googleapis.com/v0/b/rtr-dash-demo-221116.appspot.com/o/images%2Fd516bf01-cefc-4b4e-a305-e4b1d47b2572.png?alt=media&token=1bfdeadf-63df-4e95-8c8d-ecc9b2855505"
          timeout="7000"
          onClick={(e) => console.log(e)}
        />
        <Popover
          anchorEl={notificationsEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          onClose={this.handleCloseNotifications}
          open={showNotifications}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <NotificationList
            notifications={notifications}
            onSelect={this.handleCloseNotifications}
            handleClickNotif={this.handleClickNotif}
            handleDeleteNotification={this.handleDeleteNotification}
          />
        </Popover>
      </Fragment>
    );
  }
}

Topbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isSidebarOpen: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
  title: PropTypes.string
};

Topbar.defaultProps = {
  onToggleSidebar: () => {}
};

export default compose(
  withRouter,
  withStyles(styles)
)(Topbar);
