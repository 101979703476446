import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { LeadTable, OptionsBar, TemplateModal } from './components';
import { db } from '../../database/firebase';
import moment from 'moment';

import './styles.css';

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  }
});

class LeadsAndCustomers extends Component {
  state = {
    data: [],
    templates: [],
    selectedData: [],
    bID: '',
    open: false
  };

  componentDidMount(){
    this.verify()
  }

  verify = () => {
    let uid = localStorage.getItem('uid');

    db.ref(`users/${uid}/bID`)
    .once('value', (snap) => {
      let bID = localStorage.getItem('rtc_bid') || snap.val()[0];
      this.setState({ bID }, () => {
        this.getLeadData()
        this.getTemplates()
      });
    })
  }

  getLeadData = () => {
    const { bID } = this.state;
    let data = [];

    db.ref(`companies/${bID}/inbox`)
    .once('value', (snap) => {
      snap.forEach((n) => {
        let x = n.val();
        let key = n.key;
        let name = x.contactName;
        let number = x.contactNumber;
        let type = x.type;
        let leadType = x.leadType || "Lead";
        let status = x.status || "Unassigned";
        let time = x.time;
        let date = moment(time).format('MM/DD/YYYY')
        data.push({ key, name, number, type, leadType, status, time, date })
      })
    })
    .then(() => data.sort((a,b) => a.time < b.time ? 1 : -1))
    .then(() => this.setState({ data }))
  };

  getTemplates = () => {
    const { bID } = this.state;
    let templates = [];

    db.ref(`companies/${bID}/templates`)
    .once('value', (snap) => {
      snap.forEach((n) => {
        let key = n.key;
        let title = n.val().title;
        let body = n.val().body;
        templates.push({ key, title, body })
      })
    })
    .then(() => this.setState({ templates }))
  }

  handleSelect = (e, i) => {
    let { data } = this.state;
    let selectedData = []
    i.map((x) => {
      let index = x.index;
      return selectedData.push(data[index]);
    })
    this.setState({ selectedData })
  }

  handleDelete = (e) => {
    const { selectedData, bID } = this.state;
    if(selectedData.length > 0){
      selectedData.map((x) => {
        let key = x.key;
        return db.ref(`companies/${bID}/inbox/${key}`)
        .remove()
      })
    }
  }

  handleChangeStatus = status => {
    const { selectedData, bID } = this.state;
    if(selectedData.length > 0){
      let saveData = selectedData.map((x) => {
        let key = x.key;
        return db.ref(`companies/${bID}/inbox/${key}`)
        .update({ status })
      })
      Promise.all(saveData)
      .then(() => {
        this.setState({ data: [], selectedData: [] }, () => {
          this.getLeadData()
        })
      })
    }
  };

  handleChangeType = leadType => {
    const { selectedData, bID } = this.state;
    if(selectedData.length > 0){
      let saveData = selectedData.map((x) => {
        let key = x.key;
        return db.ref(`companies/${bID}/inbox/${key}`)
        .update({ leadType })
      })
      Promise.all(saveData)
      .then(() => {
        this.setState({ data: [], selectedData: [] }, () => {
          this.getLeadData()
        })
      })
    }
  };

  saveTemplate = (title, body) => {
    const { bID } = this.state;

    db.ref(`companies/${bID}/templates`)
    .push({ title, body })
    .then(() => this.setState({ open: false }, () => {
      this.getTemplates()
    }))
  }

  render() {
    const { classes } = this.props;

    const { data, selectedData, templates, open, bID } = this.state;

    const options = {
      filterType: 'checkbox',
      textLabels: {
        body: {
          noMatch: "No data"
        }
      },
      onRowsSelect: (e,i) => this.handleSelect(e,i),
      onRowsDelete: (e) => this.handleDelete(e)
    };

    return (
      <DashboardLayout title="Leads and Customers">
        <div className={classes.root}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <LeadTable
                data={data}
                options={options}
                />
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={selectedData.length < 1 ? "option-container_disabled" : "option-container_enabled"}>
                <OptionsBar
                  selectedData={selectedData}
                  templates={templates}
                  bID={bID}
                  handleChangeStatus={this.handleChangeStatus}
                  handleChangeType={this.handleChangeType}
                  openNewTemplate={() => this.setState({ open: true })}
                  />
              </div>
            </Grid>
          </Grid>
          <TemplateModal
            open={open}
            handleClose={(e) => this.setState({ open: false })}
            saveTemplate={this.saveTemplate}
            />
        </div>
      </DashboardLayout>
    );
  }
}

LeadsAndCustomers.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(LeadsAndCustomers);
