import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { TextField, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import axios from 'axios';

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  unselected: {
    display: 'flex'
  },
  selected: {

  }
});

function SimpleDialog(props) {

  const classes = useStyles();

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [uid, setUID] = useState('')

  const { onClose, selectedValue, handleChooseUser, ...other } = props;

  function handleClose() {
    onClose(selectedValue);
  }

  const chooseUser = user => e => {
    const { name, phone, key } = user;

    setName(name)
    setPhone(phone)
    setUID(key)
  }

  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      {...other}>
      <DialogTitle style={{ textAlign: 'center' }} id="simple-dialog-title">
        Assign this conversation to..
      </DialogTitle>
      <List style={{ maxHeight: '40vh', overflow: 'auto', overflowX: 'hidden' }}>
        {
          props.employees &&
          props.employees.map((x,i) => {
            return (
              <ListItem
                button
                key={x+i}
                style={{ display: 'flex', justifyContent: 'center' }}
                className={
                  x.name === name ?
                  "tech_item_selected" :
                  "tech_item"
                }
                onClick={chooseUser(x)}>
                <ListItemAvatar>
                  <Avatar src={x.avatar} className={classes.avatar}/>
                </ListItemAvatar>
                <div>
                  <h2 style={{ marginBottom: 0 }}>{x.name}</h2>
                  <p style={{ marginTop: 0 }}>{x.phone}</p>
                </div>
              </ListItem>
            )
          })
      }
      </List>
      <div style={{ borderTop: '1px solid gray', padding: 23 }}>
        <h4 style={{ marginBottom: 10 }}>Add a note</h4>
        <TextField
          fullWidth
          variant="outlined"
          onChange={props.onChange}
          placeholder="Enter your note.."
          />
      </div>
      <div style={{ padding: 12, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={props.assign({ name, phone, uid })}>
          Assign Chat
        </Button>
      </div>
    </Dialog>
  );
}

export default function AssignModal({ bID, roomID, assignNotify, employees }) {
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState('');

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  };

  const assign = ({ name, phone, uid }) => e => {
    axios({
      method: "POST",
      url: "https://backend.realtimechat.com/assign",
      data: {
        bID,
        roomID,
        phone,
        note,
        uid
      }
    }).then(() => {
      handleClose()
      assignNotify({ name, uid })
    })
  }

  return (
    <div>
      <Tooltip title="Assign to Tech">
        <IconButton onClick={handleClickOpen}>
          <i className="fas fa-user-tag" />
        </IconButton>
      </Tooltip>
      <SimpleDialog
        open={open}
        assign={assign}
        employees={employees}
        onClose={handleClose}
        onChange={(e) => setNote(e.target.value)}/>
    </div>
  );
}
