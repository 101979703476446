import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { auth, db } from '../../database/firebase';
import {
  Leads,
  ChatRating,
  WebVisits,
  PageViews,
  DevicesChart,
  LiveFeed,
  LeadTable
} from './components';
import * as constant from '../../constants.js';

const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  },
  item: {
    height: '100%'
  }
});

class Dashboard extends Component {

  state = {
    views: [],
    rank: 0,
    leads: []
  };

  componentDidMount(){
    this.getData();
  };

  getData = () => {
    auth.onAuthStateChanged((user) => {
      if(user){
        if(user.email.includes('@trenchlessmarketing.com') || user.email === 'godmode@email.com' || constant.RTM === 0){
          db.ref(`users/${user.uid}/bID`)
          .once('value', (snap) => {
            let bID = localStorage.getItem('rtc_bid') || snap.val()[0];
            this.setState({ bID }, () => {
              this.getViews()
            })
          })
        } else {
          auth.signOut();
        }
      }
    })
  }

  getViews = () => {
    const { bID } = this.state;
    let views = []
    db.ref(`companies/${bID}/pageViews`)
    .once('value', (snap) => {
      snap.forEach((n) => {
        let x = n.val();
        views.push(x)
      })
    })
    .then(() => this.setState({ views }))
    .then(() => this.getLeads())
  }

  getLeads = () => {
    const { bID } = this.state;
    let leads = []
    db.ref(`companies/${bID}/inbox`)
    .once('value', (snap) => {
      snap.forEach((n) => {
        leads.push(n.val())
      })
    })
    .then(() => this.setState({ leads }, () => {
      this.getRating()
    }))
  }

  getRating = () => {
    const { bID } = this.state;

    let hasVal = false;

    let ratings = []
    db.ref(`companies/${bID}/ratings`)
    .once('value', (snap) => {
      if(snap.val()){
        hasVal = true;
        snap.forEach((n) => {
          let x = n.val();
          let rating = x.rating;
          ratings.push(rating)
        })
      }
    })
    .then(() => {
      if(hasVal){
        const r = (a,b) => (a + b);
        let b = ratings.reduce(r);
        let rank = (b / ratings.length).toFixed(1)
        this.setState({ rank })
      } else {
        this.setState({ rank: 0 })
      }
    })
  }

  render() {
    const { classes } = this.props;
    const { views, leads, rank, bID } = this.state;

    return (
      <DashboardLayout title="Dashboard">
        <div className={classes.root}>
          <Grid
            container
            spacing={4}
          >
            <Grid
              item
              lg={4}
              sm={6}
              xl={4}
              xs={12}
            >
              <Leads leads={leads.length} className={classes.item} />
            </Grid>
            <Grid
              item
              lg={4}
              sm={6}
              xl={4}
              xs={12}
            >
              <ChatRating rank={rank} className={classes.item} />
            </Grid>
            <Grid
              item
              lg={4}
              sm={6}
              xl={4}
              xs={12}
            >
              <WebVisits views={views.length} className={classes.item} />
            </Grid>
            {/*<Grid
              item
              lg={3}
              sm={6}
              xl={3}
              xs={12}
            >
              <Profit className={classes.item} />
            </Grid>*/}
            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >
              <PageViews bID={bID} className={classes.item} />
            </Grid>
            <Grid
              item
              lg={4}
              md={6}
              xl={3}
              xs={12}
            >
              <DevicesChart className={classes.item} />
            </Grid>
            <Grid
              item
              lg={4}
              md={6}
              xl={3}
              xs={12}
            >
              <LiveFeed className={classes.item} />
            </Grid>
            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >
              <LeadTable className={classes.item} />
            </Grid>
          </Grid>
        </div>
      </DashboardLayout>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Dashboard);
