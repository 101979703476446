import React, { Component } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  CircularProgress,
  Table,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
} from 'components';
import { auth, db } from '../../../../database/firebase';

import styles from './styles';

// const statusColors = {
//   finished: 'success',
//   chatting: 'info',
// };

class OrdersTable extends Component {

  state = {
    isLoading: false,
    limit: 10,
    orders: [],
    leads: [],
    ordersTotal: 0,
    bID: ''
  };


  componentDidMount() {
    this.getData()
  }

  getData = () => {
    let leads = []
    auth.onAuthStateChanged((user) => {
      if(user){
        db.ref(`users/${user.uid}/bID`)
        .once('value', (snap) => {
          let bID = localStorage.getItem('rtc_bid') || snap.val()[0];
          this.setState({ bID })
        })
        .then(() => {
          db.ref(`companies/${this.state.bID}/inbox`)
          .once('value', (snap) => {
            snap.forEach((n) => {
              let x = n.val();
              const key = n.key;
              const name = x.contactName;
              const number = x.contactNumber;
              const type = x.type;
              const createdAt = x.time;
              const message = x.message;
              leads.push({ key, name, number, type, createdAt, message })
            })
          })
          .then(() => {
            leads.sort((a,b) => a.createdAt < b.createdAt ? 1 : -1)
            this.setState({ leads })
          })
        })
      }
    })
  }

  render() {
    const { classes, className } = this.props;
    const { isLoading, leads } = this.state;

    const rootClassName = classNames(classes.root, className);
    const showOrders = !isLoading && leads.length > 0;

    return (
      <Portlet className={rootClassName}>
        <PortletHeader noDivider>
          <PortletLabel
            classes={{ subtitle: classes.subtitle }}
            subtitle={`${leads.length} in total`}
            title="Recent leads"
          />
        <div>
          <NavLink to="/leads-customers">
            <Button variant="outlined">View All</Button>
          </NavLink>
        </div>
        </PortletHeader>
        <PerfectScrollbar>
          <PortletContent
            className={classes.portletContent}
            noPadding
          >
            {isLoading && (
              <div className={classes.progressWrapper}>
                <CircularProgress />
              </div>
            )}
            {showOrders && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell align="left">Lead Name</TableCell>
                    <TableCell align="left">Message</TableCell>
                    <TableCell align="left">Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    //eslint-disable-next-line
                    leads.map((lead, i) => {
                      if(i < 10){
                        return (
                          <TableRow
                            className={classes.tableRow}
                            hover
                            onClick={() => console.log(lead)}
                            key={lead.key}
                          >
                          <TableCell>
                            {moment(lead.createdAt).format('MM/DD/YYYY')}
                          </TableCell>
                            <TableCell className={classes.customerCell}>
                              {lead.name}
                            </TableCell>
                            <TableCell className={classes.customerCell}>
                              {lead.message ?
                                lead.message.length > 30 ?
                                lead.message.substr(0,30) + '..' :
                                lead.message : ""}
                            </TableCell>
                            <TableCell>
                              <div className={classes.statusWrapper}>
                                {lead.type}
                              </div>
                            </TableCell>
                          </TableRow>
                        )
                      }
                    })
                  }

                </TableBody>
              </Table>
            )}
          </PortletContent>
        </PerfectScrollbar>
      </Portlet>
    );
  }
}

OrdersTable.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(OrdersTable);
