import 'date-fns';
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function MaterialUIPickers({ startDate, saveEnd, saveStart }) {

  const [selectedDate, setSelectedDate] = useState(startDate);
  const [endDate, setEndDate] = useState(startDate)

  const [hasNewStart, isNewStart] = useState(false);
  //eslint-disable-next-line
  const [hasNewEnd, isNewEnd] = useState(false);

  const onStartDateChange = (date) => {
    setSelectedDate(date);
    saveStart(date);
    isNewStart(true);
  }

  const onEndDateChange = (date) => {
    setEndDate(date);
    saveEnd(date);
    isNewEnd(true);
  }

  useEffect(() => {
    // console.log("START ", selectedDate);
    // console.log("END ",endDate);
  })

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
        <Grid item style={{ display: 'flex', justifyContent: 'space-around'}}  md={12}>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Start Date"
            format="MM/dd/yyyy"
            value={!hasNewStart ? startDate : selectedDate}
            onChange={onStartDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <KeyboardTimePicker
            margin="normal"
            id="time-picker"
            label="Start Time"
            value={selectedDate}
            onChange={onStartDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />
        </Grid>
        <Grid item style={{ display: 'flex', justifyContent: 'space-around'}}  md={12}>
          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="End Date"
            format="MM/dd/yyyy"
            value={endDate}
            onChange={onEndDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          <KeyboardTimePicker
            margin="normal"
            id="time-picker"
            label="End Time"
            value={endDate}
            onChange={onEndDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
