import { auth } from '../database/firebase';

export const login = (uid) => ({
  type: "LOGIN",
  uid
});

export const tokenLogin = (uid) => ({
  type: "TOKEN_LOGIN",
  uid
})

export const logout = (token) => ({
  type: 'LOGOUT',
  token
});

export const startLogin = (email, password) => {
  return () => {
    return auth.signInWithEmailAndPassword(email,password)
  }
}

export const startTokenLogin = (token) => {
  return () => {
    return auth.signInWithCustomToken(token).then(function() {
      console.log('TOKEN LOGIN SUCCESS');
      window.location.replace(`/dashboard`)
    }).catch(function(error) {
      console.log('TOKEN LOGIN ERROR: ', error);
      auth.signOut().then(function() {
        window.location.replace(`/sign-in`)
      })
    });
  }
}



export const startLogout = (token) => {
  return () => {
    return auth.signOut()
  };
};
