import React, { Component } from 'react';
import { Dashboard as DashboardLayout } from 'layouts';
import { auth, db } from '../../database/firebase';
import { withStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import ChatBox from './ChatBox';
import style from './styles';
import moment from 'moment';

// const online = {
//   width: '20px',
//   height: '20px',
//   borderRadius: '50%',
//   alignSelf: 'flex-end',
//   background: '#6bff84',
//   boxShadow: '0px 0px 10px #6bff84'
// }
//
// const offline = {
//   width: '20px',
//   height: '20px',
//   borderRadius: '50%',
//   alignSelf: 'flex-end',
//   background: 'lightcoral',
//   boxShadow: '0px 0px 10px lightcoral'
// }

class Chats extends Component {

  state = {
    roomID: '',
    bID: "",
    rooms: [],
    baseData: []
  }

  componentDidMount(){
    auth.onAuthStateChanged((user) => {
      db.ref(`users/${user.uid}/bID`).once('value', (snap) => {
        let bID = localStorage.getItem('rtc_bid') || snap.val()[0];
        this.setState({ bID });
      })
      .then(() => {
        this.setState({ user: user.uid }, () => {
          this.getChatData()
        })
      })
    })
  }

  getChatData = () => {
    let rooms = [], baseData = []
    let { bID } = this.state;

    db.ref(`companies/${bID}/name`)
    .once('value', (x) => this.setState({ companyName: x.val() }))

    db.ref(`companies/${bID}/rooms`).on('value', (snap) => {
      rooms = []
      snap.forEach((n) => {
        let data = n.val()
        let roomID = n.key;
        let m = data.messages.slice(-1)[0];
        let t = m.time;
        let lastTime = moment(t).format('ddd, hh:mm a');
        let lastMessage = m.text;
        rooms.push({ data, roomID, lastMessage, lastTime, t })
        baseData.push({ data, roomID, lastMessage, lastTime, t })
        rooms.sort((a,b) => a.t < b.t ? 1 : -1)
        this.setState({ rooms, baseData })
      })
    })
  }

  filterChats = e => {
    let { baseData } = this.state;
    let text = e.target.value;
    let rooms = baseData.filter((x) => x.data.name.toLowerCase().includes(text.toLowerCase()))
    rooms.sort((a,b) => a.t < b.t ? 1 : -1)
    this.setState({ rooms })
  }

  handleChooseRoom = roomID => e => {
    this.setState({ roomID })
  }

  render(){

    const { classes } = this.props;
    const { roomID, bID, rooms } = this.state;

    return (
      <DashboardLayout title="Chats">
        <div className={classes.root}>
          <div style={{ display: 'flex', width: '-webkit-fill-available', height: '100%', overflow: 'hidden', border: '1px solid lightgray', borderRadius: 10 }}>
            <div className={classes.chatRoomContainer}>
              <div style={{ padding: '30px 0' }}>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Search…"
                    variant="outlined"
                    onChange={this.filterChats}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </div>
              </div>
              <hr />
              {
                rooms.length > 0 ? (
                  rooms.map((x,i) => {
                    return (
                      <div key={x+i}>
                        <div
                          onClick={this.handleChooseRoom(x.roomID)}
                          className="room_item">
                          <div>
                            <p style={{ textAlign: 'start', fontSize: '23px', opacity: '0.8', fontWeight: '800' }}>{x.data.name}</p>
                            <p style={{ opacity: '0.5', fontWeight: '300', textAlign: 'start' }}>
                              {
                                x.lastMessage.length >= 23 ? x.lastMessage.substr(20, '...') :
                                x.lastMessage
                              }
                            </p>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <p style={{ paddingTop: '8px', opacity: '0.7', fontSize: '15px', fontWeight: '400', fontStyle: 'italic' }}>
                              {x.lastTime}
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  })
                ) : (
                  <div className="room_item_empty">
                    <p>No Chats!</p>
                  </div>
                )
              }
            </div>
            <div style={{ width: '100%' }}>
              <ChatBox
                id={bID}
                roomID={roomID}
                />
            </div>
          </div>
        </div>
      </DashboardLayout>
    )
  }
}

export default withStyles(style)(Chats)
