import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Paper } from 'components';
import styles from './styles';

class WebVisits extends Component {

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="body2"
            >
              WEB VIEWS (This month)
            </Typography>
            <Typography
              className={classes.value}
              variant="h3"
            >
              {this.props.views ||  "..."}
            </Typography>
          </div>
          <div className={classes.iconWrapper}>
            <i style={{ color: 'white', fontSize: '30px' }} className="fas fa-eye" />
          </div>
        </div>
        {/*<div className={classes.footer}>
          <LinearProgress
            value={75.5}
            variant="determinate"
          />
        </div>*/}
      </Paper>
    );
  }
}

WebVisits.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(WebVisits);
