import React, { useEffect } from 'react';
import { auth } from '../../database/firebase';

export default function Logout(){

  useEffect(() => {
  	let token = window.location.pathname.split('/')[2];
  	if (token && token !== '') {
  		auth.signOut()
	    .then(() => {
	    	window.location.replace(`/sign-in/${token}`)
	    })
  	} else {
  		auth.signOut()
	    .then(() => {
	    	window.location.replace('/sign-in')
	    })
  	}
  },[])

  return (
    <div></div>
  )
}
