import React, { useState, useEffect } from 'react';
import Menu from './Menu';
import Avatar from '@material-ui/core/Avatar';
import AssignModal from './AssignModal';
import { auth, db } from '../../database/firebase'
import './styles.css';

export default function ChatHeader({ roomID, employees, assignNotify, handleEndConvo, handleDeleteConvo }){

  const [avatar, setAvatar] = useState('');
  const [bID, setBID] = useState('')

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      db.ref(`users/${user.uid}`).once('value', (snap) => {
        let avatar = snap.val().avatar;
        let bID = localStorage.getItem('rtc_bid') || snap.val().bID[0]
        setAvatar(avatar)
        setBID(bID)
      })
    })
  })

  return (
    <div className="chat_topbar">
      <div className="user_avatar">
        <Avatar src={avatar} />
      </div>
      <div>
        <AssignModal
          bID={bID}
          roomID={roomID}
          employees={employees}
          assignNotify={assignNotify}
          />
      </div>
      <div>
        <Menu
          handleEndConvo={handleEndConvo}
          handleDeleteConvo={handleDeleteConvo}
          />
      </div>
    </div>
  )
}
