import React, { Component } from 'react';
import ChatHeader from './ChatHeader';
import { db } from '../../database/firebase';
import moment from 'moment';
import './styles.css';

const FromThem = ({ text, time }) => (
  <div>
    <div style={{ padding: '10px', borderRadius: '10px 10px 10px 0', background: 'lightgray' }}>
      <p style={{ fontSize: '18px', margin: 0}}>{text}</p>
    </div>
    <p style={{ opacity: '.7', fontSize: '12px', textAlign: 'start' }}>{moment(time).format('MMM Do, hh:mm a')}</p>
  </div>
);

const FromMe = ({ text, time }) => (
  <div>
    <div style={{ padding: '10px', background: "#0B93F6", color: 'white', borderRadius: '10px 10px 0' }}>
      <p style={{ fontSize: '18px', margin: 0}}>{text}</p>
    </div>
    <p style={{ opacity: '.7', fontSize: '12px', textAlign: 'end' }}>{moment(time).format('MMM Do, hh:mm a')}</p>
  </div>
);

const ConversationEnd = ({ text, time }) => (
  <div>
    <div style={{ padding: '10px' }}>
      <p style={{ fontSize: '18px', margin: 0, opacity: "0.7" }}>{text}</p>
    </div>
    <p style={{ opacity: '.7', fontSize: '12px', textAlign: 'center' }}>{moment(time).format('MMM Do, hh:mm a')}</p>
  </div>
)

class ChatBox extends Component {

  state = {
    chat: [],
    employees: [],
    text: '',
    bID: '',
    roomIndex: 0,
    hasChat: false
  }

  componentWillReceiveProps(next){
    let chat = [], employees = []
    db.ref(`companies/${next.id}/rooms/${next.roomID}/messages`)
    .on('child_added', (snap) => {
      chat.push(snap.val())
      this.setState({ chat, roomID: next.roomID, bID: next.id, hasChat: true }, () => {
        this.scrollToBottom()
      })
    })
    db.ref(`companies/${next.id}/employees`)
    .once('value', (snap) => {
      snap.forEach((n) => {
        let key = n.key;
        let x = n.val()
        let name = x.name;
        let phone = x.phone;
        let avatar = x.avatar;
        employees.push({ key, name, phone, avatar })
      })
    })
    .then(() => this.setState({ employees }))
    .then(() => {
      let rooms = []

      db.ref(`companies/${next.id}/rooms`)
      .once('value', (snap) => {
        snap.forEach((n) => {
          rooms.push(n.key)
        })
      }).then(() => {
        let roomIndex = rooms.indexOf(next.roomID)
        this.setState({ roomIndex })
      })
    })
  }

  onSubmit = (e) => {
    let { text, bID, roomID } = this.state;
    e.preventDefault()
    if(text.length > 0){
      fetch('https://backend.realtimechat.com/outgoing', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          bID,
          roomID,
          text
        })
      }).then((res) => {
        this.setState({ text: '' }, () => {
          this.scrollToBottom()
        })
      })
    }
  }

  handleDeleteConvo = () => {
    let { roomID } = this.state;
    db.ref(`companies/${this.props.id}/rooms/${this.props.roomID}/messages`)
    .once('value', (snap) => {
      let i = snap.val().length
      db.ref(`companies/${this.props.id}/rooms/${this.props.roomID}/messages/${i}`)
      .set({
        direction: 'out',
        text: "Ended Conversation",
        isEnd: true,
        time: moment().valueOf()
      })
      .then(() => {
        let count = []
        db.ref(`companies/${this.props.id}/rooms`)
        .once('value', (snap) => {
          snap.forEach((x) => {
            let i = x.val()
            count.push(i)
          })
        }).then(() => {
          localStorage.setItem('chat', count.length - 1)
        })
        .then(() => {
          db.ref(`companies/${this.props.id}/rooms/${roomID}`).remove()
          .then(() => this.setState({ hasChat: false }))
          // .then(() => this.props.getChatData())
        })
      })

    })
  }

  handleEndConvo = () => {
    db.ref(`companies/${this.props.id}/rooms/${this.props.roomID}/messages`)
    .once('value', (snap) => {
      let i = snap.val().length
      db.ref(`companies/${this.props.id}/rooms/${this.props.roomID}/messages/${i}`)
      .set({
        direction: 'out',
        text: "Ended Conversation",
        isEnd: true,
        time: moment().valueOf()
      })
      .then(() => this.setState({ text: '' }))
    })
  }

  assignNotify = data => {
    const { bID, roomID } = this.state;
    const { name } = data;

    const text = `I'm transferring you over to ${name}, they will be with you in a moment!`

    fetch('https://backend.realtimechat.com/outgoing', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        bID,
        roomID,
        text,
      })
    }).then((res) => {
      this.setState({ text: '' }, () => {
        this.scrollToBottom()
      })
    })
  }

  animateScroll = (duration) => {
    let chatBox = document.getElementById('chat-box');
    var start = chatBox.scrollTop;
    var end = chatBox.scrollHeight;
    var change = end - start;
    var increment = 20;
    function easeInOut(currentTime, start, change, duration) {
      currentTime /= duration / 2;
      if (currentTime < 1) {
        return change / 2 * currentTime * currentTime + start;
      }
      currentTime -= 1;
      return -change / 2 * (currentTime * (currentTime - 2) - 1) + start;
    }
    function animate(elapsedTime) {
      elapsedTime += increment;
      var position = easeInOut(elapsedTime, start, change, duration);
      chatBox.scrollTop = position;
      if (elapsedTime < duration) {
        setTimeout(function() {
          animate(elapsedTime);
        }, increment)
      }
    }
    animate(0);
  }

  scrollToBottom = () => {
    var duration = 300
    this.animateScroll(duration);
  }

  render(){

    const { chat, text, hasChat, employees, roomIndex } = this.state;
    const onSubmit = this.onSubmit;

    const fromThem = { padding: '10px', alignSelf: 'flex-start' }
    const fromMe = { padding: '10px', alignSelf: 'flex-end' }
    const end = { padding: '10px', alignSelf: 'center' }

    return (
      <div className="chat-container">
        <div style={{ flex: '1' }}>
          <div style={{ padding: '0 30px', marginBottom: '50px' }}>
            {
              hasChat &&
              <ChatHeader
                roomID={roomIndex}
                employees={employees}
                assignNotify={this.assignNotify}
                handleOpenMenu={this.props.handleOpenMenu}
                handleEndConvo={this.handleEndConvo}
                handleDeleteConvo={this.handleDeleteConvo}
                />
            }
          </div>
          <div
            id="chat-box"
            className="chat_box"
            >
            {
              hasChat &&
              chat.map((x,i) => {
                return (
                  <div
                    key={x+i}
                    style={
                      x.direction === 'in' && !x.isEnd ? fromThem :
                      x.direction === 'out' && !x.isEnd ? fromMe : end
                    }>
                    {
                      x.direction === 'in' && !x.isEnd ?
                        <FromThem time={x.time} text={x.text} /> :
                      x.direction === 'out' && !x.isEnd ?
                        <FromMe time={x.time} text={x.text} /> :
                        <ConversationEnd time={x.time} text={x.text} />
                    }
                  </div>
                )
              })
            }
            {
              !hasChat &&
              <div className="no_chat_container">
                <div>
                  <img
                    alt=""
                    style={{ maxWidth: '100px' }}
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4uKeLv60oBKySRfPvxLcibj3sEPNzPdp3feberRXwmH7n4MrpOw" />
                </div>
              </div>
            }
          </div>
          <div style={{ padding: '0 30px', paddingBottom: '20px' }}>
            <div style={{ display: 'flex' }}>
              {
                hasChat &&
                <form style={{ width: '100%', display: 'flex' }} onSubmit={onSubmit}>
                  <input
                    placeholder="Type your message here..."
                    className="chat_input"
                    value={text}
                    onChange={(e) => this.setState({ text: e.target.value })}
                    />
                  <div style={{ paddingLeft: '10px', margin: 'auto' }}>
                    <button style={{ background: 'inherit', padding: '10px', border: 'none', outline: 'none', cursor: 'pointer' }} type="submit">
                      <i style={{ fontSize: '17px' }} className="far fa-paper-plane"></i>
                    </button>
                  </div>
                </form>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ChatBox;
