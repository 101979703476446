import React from 'react';
import { Switch, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import RedirectRoute from './RedirectRoute';
import SignIn from '../views/SignIn';
import SetUp from '../views/SetUp';
import Dashboard from '../views/Dashboard';
import Chats from '../views/Chats/Chats';
import Users from '../views/UserList';
import Account from '../views/Account';
import Settings from '../views/Settings';
import Calendar from '../views/Calendar';
import Tutorials from '../views/Tutorials';
import LeadsAndCustomers from '../views/LeadsAndCustomers';
import Logout from '../views/Logout';
// import SignUp from '../views/SignUp';

export const history = createBrowserHistory();

const AppRouter = () => (
  <Router history={history}>
    <div>
      <Switch>
        <PublicRoute path="/sign-in" component={SignIn} />
        <PublicRoute path="/sign-in/:id" component={SignIn} />
        <PublicRoute path="/set-up" component={SetUp} />
        {/*<PublicRoute path="/sign-up" component={SignUp} />*/}
        <PrivateRoute path="/calendar-token/:id" component={Calendar} />
        <PrivateRoute path="/logout" component={Logout} exact/>
        <PrivateRoute path="/dashboard" component={Dashboard} exact/>
        <PrivateRoute path="/leads-customers" component={LeadsAndCustomers} exact/>
        <PrivateRoute path="/chats" component={Chats} exact/>
        <PrivateRoute path="/users" component={Users} exact/>
        <PrivateRoute path="/account" component={Account} exact/>
        <PrivateRoute path="/calendar" component={Calendar} exact/>
        <PrivateRoute path="/settings" component={Settings} exact/>
        <PrivateRoute path="/tutorials" component={Tutorials} exact/>
        <RedirectRoute />
      </Switch>
    </div>
  </Router>
)

 export default AppRouter;
