import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { auth, db } from '../../../../database/firebase';
import {
  Avatar,
  Badge,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import {
  DashboardOutlined as DashboardIcon,
  DateRange as DateIcon,
  QuestionAnswer as QuestionIcon,
  PeopleOutlined as PeopleIcon,
  InfoOutlined as InfoIcon,
  AccountBoxOutlined as AccountBoxIcon,
  SettingsOutlined as SettingsIcon
} from '@material-ui/icons';
import styles from './styles';

class Sidebar extends Component {

  state = {
    avatar: '',
    name: '',
    rooms: []
  }

  componentDidMount(){
    let user = localStorage.getItem('uid')
    db.ref(`users/${user}`)
    .once('value', (snap) => {
      let bID = localStorage.getItem('rtc_bid') || snap.val().bID[0]
      const name = (snap.val().name === snap.val().lastName) ? snap.val().email : snap.val().name + ' ' + snap.val().lastName;
      this.setState({
        avatar: snap.val().avatar,
        bID,
        name
      })
    })
    .then(() => {
      let chatCount = localStorage.getItem('chat');
      db.ref(`companies/${this.state.bID}/rooms`)
      .on('child_added', (snap) => {
        this.state.rooms.push(snap.val())
        this.setState({ updated: true })
        if(this.state.rooms.length > chatCount){
          this.playSound()
          localStorage.setItem('chat', this.state.rooms.length)
        }
      })
    })
  }

  handleOpenSupport = () => {
    document.getElementById("deskWidgetMain").className = "desk--widget dark bottom-right widget-open"
  };

  playSound = () => {
    let audio = document.getElementById("notif");
    audio.play()
  };

  signOut = () => {
    const { bID } = this.state;

    localStorage.removeItem('rtc_bid');

    db.ref(`companies/${bID}/isOnline`)
    .set(false)
    .then(() => auth.signOut())
  };

  render() {
    const { classes, className } = this.props;

    const { avatar, name, rooms } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <nav className={rootClassName}>
        <div className={classes.logoWrapper}>
          <Link
            className={classes.logoLink}
            to="/"
          >
          </Link>
        </div>
        <Divider className={classes.logoDivider} />
        <div className={classes.profile}>
          <Link to="/account">
            <Avatar
              alt=""
              className={classes.avatar}
              src={avatar}
            />
          </Link>
          <Typography
            className={classes.nameText}
            variant="h6"
          >
            {name}
          </Typography>
          <Typography
            className={classes.bioText}
            variant="caption"
          >
          </Typography>
        </div>
        <Divider className={classes.profileDivider} />
        <List
          component="div"
          disablePadding
        >
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/dashboard"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Dashboard"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/chats"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Badge className={classes.margin} badgeContent={rooms.length} color="primary">
                <i className="far fa-comment" />
              </Badge>
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Chats"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/leads-customers"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <i className="fas fa-receipt" />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Leads & Customers"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/calendar"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <DateIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Calendar"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/users"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Users"
            />
          </ListItem>
          {/*<ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/users"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <ArchiveIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Leads"
            />
          </ListItem>*/}
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/account"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Account"
            />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={NavLink}
            to="/settings"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Settings"
            />
          </ListItem>
          <ListItem
            onClick={this.signOut}
            className={classes.listItem}
            component="a"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <i className="fas fa-sign-out-alt" />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Log Out"
            />
          </ListItem>
        </List>
        <Divider className={classes.listDivider} />
        <List
          component="div"
          disablePadding
        >
        <ListItem
          activeClassName={classes.activeListItem}
          className={classes.listItem}
          component={NavLink}
          to="/tutorials"
          >
          <ListItemIcon className={classes.listItemIcon}>
            <QuestionIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary="Tutorials & FAQ"
          />
        </ListItem>
        <ListItem
          className={classes.listItem}
          onClick={this.handleOpenSupport}
          component="a"
        >
          <ListItemIcon className={classes.listItemIcon}>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary="Customer support"
          />
        </ListItem>
        </List>
      </nav>
    );
  }
}

export default withStyles(styles)(Sidebar);

// subheader={
//   <ListSubheader className={classes.listSubheader}>
//     Support
//   </ListSubheader>
// }

// <ListItem
//   activeClassName={classes.activeListItem}
//   className={classes.listItem}
//   component={NavLink}
//   to="/products"
// >
//   <ListItemIcon className={classes.listItemIcon}>
//     <ShoppingBasketIcon />
//   </ListItemIcon>
//   <ListItemText
//     classes={{ primary: classes.listItemText }}
//     primary="Products"
//   />
// </ListItem>
// <ListItem
//   activeClassName={classes.activeListItem}
//   className={classes.listItem}
//   component={NavLink}
//   to="/sign-in"
// >
//   <ListItemIcon className={classes.listItemIcon}>
//     <LockOpenIcon />
//   </ListItemIcon>
//   <ListItemText
//     classes={{ primary: classes.listItemText }}
//     primary="Authentication"
//   />
// </ListItem>
// <ListItem
//   activeClassName={classes.activeListItem}
//   className={classes.listItem}
//   component={NavLink}
//   to="/typography"
// >
//   <ListItemIcon className={classes.listItemIcon}>
//     <TextFieldsIcon />
//   </ListItemIcon>
//   <ListItemText
//     classes={{ primary: classes.listItemText }}
//     primary="Typography"
//   />
// </ListItem>
// <ListItem
//   activeClassName={classes.activeListItem}
//   className={classes.listItem}
//   component={NavLink}
//   to="/icons"
// >
//   <ListItemIcon className={classes.listItemIcon}>
//     <ImageIcon />
//   </ListItemIcon>
//   <ListItemText
//     classes={{ primary: classes.listItemText }}
//     primary="Icons and Images"
//   />
// </ListItem>
