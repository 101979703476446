import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function FormDialog({ open, handleClose, saveTemplate }) {

  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');

  const handleSaveTemplate = () => {
    saveTemplate(title, body)
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create New Template</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            variant="outlined"
            margin="dense"
            id="name"
            label="Title"
            type="email"
            fullWidth
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            multiline
            rows={5}
            margin="dense"
            id="name"
            label="Template"
            type="email"
            fullWidth
            onChange={(e) => setBody(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveTemplate} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
