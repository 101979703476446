import React, { Component } from 'react';
import classNames from 'classnames';
import { DayEditor, ChatOptions } from './atoms';
import { withStyles, Button } from '@material-ui/core';
import Success from '../../../../components/Snackbar/Success';
import Snack from './Snack';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';
import { db } from '../../../../database/firebase';
import style from './style';

let initialState = []

class OfflineOptions extends Component {

  state = {
    bID: '',
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    sat: false,
    sun: false,
    updated: false,
    success: false,
    chat: false,
    text: false,
    review: false,
    service: false,
    serviceError: false,
    reviewError: false,
    hasChange: false,
  }

  componentWillReceiveProps({ bID }){
    bID !== this.state.bID &&
    this.setState({ bID }, () => {
      this.getData()
    })
  };

  getData = () => {
    const { bID } = this.state;
    db.ref(`companies/${bID}/offlineOptions`)
    .once('value', (snap) => {
      if(snap.val()){
        this.setState({
          monday: snap.val().monday,
          tuesday: snap.val().tuesday,
          wednesday: snap.val().wednesday,
          thursday: snap.val().thursday,
          friday: snap.val().friday,
          sat: snap.val().sat,
          sun: snap.val().sun,
          chat: snap.val().chat,
          text: snap.val().text,
          review: snap.val().review,
          service: snap.val().service
        }, () => {
          db.ref(`companies/${bID}/offlineOptions/hours`)
          .once('value', (snap) => {
            if(snap.val()){
              this.setState({ hours: snap.val() }, () => {
                this.setState({ updated: true }, () => {
                  initialState = this.state;
                })
              })
            } else {
              this.setState({ updated: true }, () => {
                initialState = this.state;
              })
            }
          })
        })
      } else {
        this.setState({ updated: true }, () => {
          initialState = this.state;
        })
      }
    })
  };

  checkState = () => {
    if(this.state !== initialState){
      this.setState({ hasChange: true })
    } else {
      this.setState({ hasChange: false })
    }
  }

  handleChange = day => {

    const { monday, tuesday, wednesday, thursday, friday, sat, sun } = this.state;

    day === 'monday' ? this.setState({ monday: !monday }, () => this.checkState()) :
    day === 'tuesday' ? this.setState({ tuesday: !tuesday }, () => this.checkState()) :
    day === 'wednesday' ? this.setState({ wednesday: !wednesday }, () => this.checkState()) :
    day === 'thursday' ? this.setState({ thursday: !thursday }, () => this.checkState()) :
    day === 'friday' ? this.setState({ friday: !friday }, () => this.checkState()) :
    day === 'sat' ? this.setState({ sat: !sat }, () => this.checkState()) :
    this.setState({ sun: !sun }, () => this.checkState())
  };

  handleStartChange = (day, time) => {
    if(day === 'monday'){
      this.setState((prevState) => ({
        hours: {
          ...prevState.hours,
          mondayStart: time
        }
      }), () => this.checkState())
    } else if(day === 'tuesday'){
      this.setState((prevState) => ({
        hours: {
          ...prevState.hours,
          tuesdayStart: time
        }
      }), () => this.checkState())
    } else if(day === 'wednesday'){
      this.setState((prevState) => ({
        hours: {
          ...prevState.hours,
          wednesdayStart: time
        }
      }), () => this.checkState())
    } else if(day === 'thursday'){
      this.setState((prevState) => ({
        hours: {
          ...prevState.hours,
          thursdayStart: time
        }
      }), () => this.checkState())
    } else if(day === 'friday'){
      this.setState((prevState) => ({
        hours: {
          ...prevState.hours,
          fridayStart: time
        }
      }), () => this.checkState())
    } else if(day === 'sat'){
      this.setState((prevState) => ({
        hours: {
          ...prevState.hours,
          satStart: time
        }
      }), () => this.checkState())
    } else if(day === 'sun'){
      this.setState((prevState) => ({
        hours: {
          ...prevState.hours,
          sunStart: time
        }
      }), () => this.checkState())
    } else {
      console.log('wtf');
    }
  };

  handleEndChange = (day, time) => {
    if(day === 'monday'){
      this.setState((prevState) => ({
        hours: {
          ...prevState.hours,
          mondayEnd: time
        }
      }), () => this.checkState())
    } else if(day === 'tuesday'){
      this.setState((prevState) => ({
        hours: {
          ...prevState.hours,
          tuesdayEnd: time
        }
      }), () => this.checkState())
    } else if(day === 'wednesday'){
      this.setState((prevState) => ({
        hours: {
          ...prevState.hours,
          wednesdayEnd: time
        }
      }), () => this.checkState())
    } else if(day === 'thursday'){
      this.setState((prevState) => ({
        hours: {
          ...prevState.hours,
          thursdayEnd: time
        }
      }), () => this.checkState())
    } else if(day === 'friday'){
      this.setState((prevState) => ({
        hours: {
          ...prevState.hours,
          fridayEnd: time
        }
      }), () => this.checkState())
    } else if(day === 'sat'){
      this.setState((prevState) => ({
        hours: {
          ...prevState.hours,
          satEnd: time
        }
      }), () => this.checkState())
    } else if(day === 'sun'){
      this.setState((prevState) => ({
        hours: {
          ...prevState.hours,
          sunEnd: time
        }
      }), () => this.checkState())
    } else {
      console.log('wtf');
    }
  };

  handleOptions = type => {

    const { chat, text } = this.state;

    type === 'chat' ? this.setState({ chat: !chat }, () => this.checkState()) :
    type === 'text' ? this.setState({ text: !text }, () => this.checkState()) :
    type === 'review' ? this.verifyReview() :
    type === 'service' ? this.verifyService() :
    console.log();
  }

  verifyReview = () => {
    const { bID, review } = this.state;

    db.ref(`companies/${bID}/rtrID`)
    .once('value', (snap) => {
      if(snap.val()){
        this.setState({ review: !review }, () => this.checkState());
      } else {
        this.setState({ reviewError: true }, () => this.checkState())
      }
    })
  }

  verifyService = () => {
    const { bID, service } = this.state;

    db.ref(`companies/${bID}/calToken`)
    .once('value', (snap) => {
      if(snap.val()){
        this.setState({ service: !service }, () => this.checkState());
      } else {
        this.setState({ serviceError: true }, () => this.checkState())
      }
    })
  }

  saveData = () => {
    const { bID, ...rest } = this.state;

    db.ref(`companies/${bID}/offlineOptions`)
    .update(rest)
    .then(() => this.setState({ success: true, hasChange: false }))
  }

  render(){

    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    const { monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            sat,
            sun,
            hours,
            chat,
            text,
            review,
            service,
            updated,
            success,
            serviceError,
            hasChange,
            reviewError } = this.state

    return (
      <Portlet {...rest} className={rootClassName}>
        <PortletHeader>
          <PortletLabel title="Open Hours & Offline Options" />
        </PortletHeader>
        <PortletContent>
          {
            updated &&
            <DayEditor
              monday={monday}
              tuesday={tuesday}
              wednesday={wednesday}
              thursday={thursday}
              friday={friday}
              sat={sat}
              sun={sun}
              hourData={hours}
              handleChange={this.handleChange}
              pHandleStartChange={this.handleStartChange}
              pHandleEndChange={this.handleEndChange}
              />
          }
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <ChatOptions
            chat={chat}
            text={text}
            review={review}
            service={service}
            handleChange={this.handleOptions}
            />
        </PortletFooter>
        <PortletFooter className={classes.portletFooter}>
          <Button
            variant={hasChange ? "contained" : undefined}
            color="primary"
            disabled={!hasChange}
            onClick={this.saveData}>
            Update
          </Button>
        </PortletFooter>
        {success &&
          <Success
            open={success}
            message="Sucessfully saved changes!"
            setOpen={() => this.setState({ success: false })}
            />}
          <Snack
            open={serviceError}
            message="Google Calendar must be integrated"
            handleClose={() => this.setState({ serviceError: false })}
            />
          <Snack
            open={reviewError}
            message="Real Time Reviews must be integrated"
            handleClose={() => this.setState({ reviewError: false })}
            />
      </Portlet>
    )
  }
};

export default withStyles(style)(OfflineOptions)
