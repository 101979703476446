import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import CompanyCalendar from './components/Calendar';
import axios from 'axios';
import { db } from '../../database/firebase';

export default class Calendar extends Component {

  componentDidMount(){
    let b = window.location.search;
    let c = b && b.replace('?','');
    let d = c && c.replace('code=', '');
    let code = d && d.split('&')[0]

    if(code){
      axios({
        method: "POST",
        url: "https://backend.realtimechat.com/get-tokens",
        data: {
          code
        }
      })
      .then(({ data }) => {
        let uid = localStorage.getItem('uid');
        let token = data.access_token;
        let refreshToken = data.refresh_token;

        db.ref(`users/${uid}/bID`)
        .once('value', (snap) => {
          let bID = snap.val()[0];
          db.ref(`companies/${bID}/calToken`)
          .set(token)
          .then(() => {
            db.ref(`companies/${bID}/refreshToken`)
            .set(refreshToken)
            .then(() => {
              window.location.search = ''
              window.location = '/calendar'
            })
          })
        })
      })
      .catch((e) => {
        console.log(e);
      })
    }
  };

  render(){
    return (
      <DashboardLayout title="Calendar">
        <Grid container>
          <Grid item md={12}>
            <CompanyCalendar />
          </Grid>
        </Grid>
      </DashboardLayout>
    )
  }
};

// <GoogleLogin
//   clientId="131274632723-5rn6i9a6ffbs2hhnqmpnscv33u3f6bfm.apps.googleusercontent.com"
//   buttonText="Login"
//   responseType="token code"
//   accessType="offline"
//   scope="https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events"
//   onSuccess={getAPIKey}
//   onFailure={getAPIKey}
//   cookiePolicy={'single_host_origin'}
// />
// <button onClick={this.getCalToken}>GET</button>

// getCalToken = () => {
//   var provider = googleAuth;
//
//   provider.addScope('https://www.googleapis.com/auth/calendar');
//   provider.addScope('https://www.googleapis.com/auth/calendar.events');
//
//   auth.signInWithPopup(provider)
//   .then((res) => console.log(res))
// }
//
// async getCalendar(){
//   let gapi = window.gapi;
//
//   let googleAuth = gapi.auth2.getAuthInstance();
//   let googleUser = await googleAuth.signIn();
//
//   let token = googleUser.getAuthResponse()
//   console.log(token);


// const get = () => {
//   axios({
//     method: "POST",
//     url: "https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=4/nQHI5cg5ZVsPEjSN1-x_6Ohes80qVu_KD3qsPMHpYNsc6rljwIF_TgrMcO9k1sQwKkOND3mcDmtc2BoDj4xZ2wQ"
//   })
//   .then((res) => {
//     console.log(res);
//   })
//   .catch((e) => {
//     console.log(e);
//   })
// };
//

// initClient = () => {
//   let gapi = window.gapi;
//   gapi.load('client', () => {
//     console.log('loaded client');
//     gapi.client.init({
//       apiKey: "AIzaSyB3x3DzUpU5-FOndzoaD9vInb9JFAQRII0",
//       client_Id: '399736727366-q8ivdm9de96kdrco927vqr0ur1dh2h45.apps.googleusercontent.com',
//       scopes: "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events",
//       accessType: "offline"
//     })
//     gapi.client.load('calendar', 'v3', () => {
//       console.log('cal loaded');
//     })
//   })
// };
