import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import { auth } from './database/firebase';
import { login, logout } from './actions/auth';
import Loader from './views/Loader/Loader';
import AppRouter, { history } from './routes/AppRouter';
import config from './store/config';
import * as serviceWorker from './common/serviceWorker';
//eslint-disable-next-line
import App from './App';
import './polyfill';
import './index.css';

const store = config()

const app = (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
  </Provider>
)

const renderApp = () => (
  ReactDOM.render(app, document.getElementById('root'))
)

const renderLoader = () => (
  ReactDOM.render(<Loader />, document.getElementById('root'))
)

renderLoader()

const getAuth = () => {
  const path = window.location.pathname; 
  let token = path.split('/')[2];
  auth.onAuthStateChanged((user) => {
    if(user && !path.includes('/logout/')){
      localStorage.setItem('uid', user.uid)
      store.dispatch(login(user.uid))
      renderApp()
      if(history.location.pathname === '/sign-in'){
        history.push('/dashboard')
      }
    } else {
      localStorage.removeItem('uid')
      store.dispatch(logout(token))
      renderApp()
      if (token && token !== '') {
        history.push(`/sign-in/${token}`)
      } else {
        history.push('/sign-in')
      }
    }
  })
}

getAuth();

serviceWorker.unregister();

// if(user){
//   localStorage.setItem('uid', user.uid)
//   db.ref(`users/${user.uid}/bID`)
//   .once('value', (snap) => {
//     let bID = snap.val()[0]
//     db.ref(`companies/${bID}/isFirstLogin`)
//     .once('value', (s) => {
//       let check = s.val();
//       if(!check && history.location.pathname === '/sign-in'){
//         history.push('/dashboard')
//         store.dispatch(login(user.uid))
//       }
//       if(check && history.location.pathname === '/sign-in'){
//         history.push('/set-up')
//       }
//     })
//   })
//   renderApp()
// } else {
//   localStorage.removeItem('uid')
//   renderApp()
//   history.push('/sign-in')
// }
