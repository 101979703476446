import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default function OptionsMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const handleDeleteConvo = () => {
    handleClose()
    props.handleDeleteConvo()
  }

  const handleEndConvo = () => {
    handleClose()
    props.handleEndConvo()
  }

  return (
    <div>
      <Tooltip title="Options">
        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
          <i className="fas fa-ellipsis-v" />
        </IconButton>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEndConvo}>End Conversation</MenuItem>
        <MenuItem onClick={handleDeleteConvo}>Delete Conversation</MenuItem>
        <MenuItem onClick={handleClose}>Block User</MenuItem>
      </Menu>
    </div>
  );
}
