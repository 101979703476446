import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import { firebase, db } from '../../../../database/firebase';
import Success from '../../../../components/Snackbar/Success';
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import './styles.css';

var config = {
  apiKey: "AIzaSyB3x3DzUpU5-FOndzoaD9vInb9JFAQRII0",
  authDomain: "realtimechat-72615.firebaseapp.com",
  databaseURL: "https://realtimechat-72615.firebaseio.com",
  projectId: "realtimechat-72615",
  storageBucket: "realtimechat-72615.appspot.com",
  messagingSenderId: "406362753010",
  appId: "1:406362753010:web:a7cc996d124dc701"
}

var secondaryApp = firebase.initializeApp(config, 'secondary');

export default function AddEmployee(props) {

  const [open, setOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [success, isSuccess] = useState(false)

  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [avatar, setAvatar] = useState('https://crescentsmile.com/wp-content/uploads/2015/07/CD-Placeholder.png')

  const [password, setPassword] = useState('')

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const handleUploadStart = () => {
    setIsUploading(true)
  }

  const handleUploadSuccess = filename => {
    setIsUploading(false)

    firebase.storage()
    .ref("images").child(filename)
    .getDownloadURL()
    .then((avatar) => setAvatar(avatar))
  }

  const createUser = () => {

    let a = phone.replace(/\D+/g,'')

    let o = [...a]

    o.splice(3,0,'-')
    o.splice(7,0,'-')
    let phoneNumber = '';

    for (var i = 0; i < o.length; i++) {
      phoneNumber += o[i]
    }

    secondaryApp.auth().createUserWithEmailAndPassword(email, password)
    .then((user) => {
      db.ref(`users/${user.user.uid}`)
      .set({
        name,
        lastName,
        avatar,
        email,
        bID: [props.bID],
        phone: phoneNumber
      })
      .then(() => {
        db.ref(`companies/${props.bID}/employees/${user.user.uid}`)
        .set({
          name,
          lastName,
          avatar,
          email,
          phone: phoneNumber
        })
        .then(() => {
          handleClose()
          props.refreshData()
        })
        .then(() => isSuccess(true))
      })
    })
  }


  return (
    <div>
      <button
        onClick={handleClickOpen}
        className="add_button"
        color="primary"
        size="small"
        variant="outlined"
      >
        Add
      </button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add New User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Fill out the fields below
          </DialogContentText>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ marginBottom: 23 }}>
              <Avatar
                src={avatar}
                style={{ width: 100, height: 100 }}
                />
            </div>
            <label style={{ backgroundColor: 'steelblue', color: 'white', padding: 10, borderRadius: 4, pointer: 'cursor' }}>
              {
                !isUploading ? "Profile Picture" : "Uploading..."
              }
              <CustomUploadButton
                hidden
                randomizeFilename
                accept="image/*"
                storageRef={firebase.storage().ref('images')}
                onUploadStart={handleUploadStart}
                onUploadSuccess={handleUploadSuccess}
                />
            </label>
          </div>
          <TextField
            autoFocus
            onChange={(e) => setName(e.target.value)}
            margin="dense"
            label="First Name"
            variant="outlined"
            fullWidth
          />
          <TextField
            onChange={(e) => setLastName(e.target.value)}
            margin="dense"
            label="Last Name"
            variant="outlined"
            fullWidth
          />
          <TextField
            margin="dense"
            onChange={(e) => setEmail(e.target.value)}
            id="name"
            label="Email Address"
            type="email"
            variant="outlined"
            fullWidth
          />
          <TextField
            onChange={(e) => setPhone(e.target.value)}
            margin="dense"
            id="name"
            label="Phone Number"
            variant="outlined"
            fullWidth
          />
          <TextField
            onChange={(e) => setPassword(e.target.value)}
            margin="dense"
            id="name"
            label="Password"
            variant="outlined"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={createUser} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Success
        open={success}
        setOpen={() => isSuccess(false)}
        message="Successfully added!"
        />
    </div>
  );
}
