import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { Checkbox, Typography, Button } from '@material-ui/core';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';
import ReactNotifications from 'react-browser-notifications';
import { db } from '../../../../database/firebase'
import styles from './styles';

class Notifications extends Component {

  state = {
    uid: '',
    settings: {
      desktop: false,
      emailNotif: false,
      textNotif: false,
      emailMessage: false,
      textMessage: false
    }
  }

  componentDidMount(){
    let uid = localStorage.getItem('uid')
    db.ref(`users/${uid}/settings`)
    .once('value', (snap) => {
      this.setState({ settings: snap.val(), uid })
    })
  }

  showNotif = () => {
     this.n.supported() && this.n.show()
  }

  handleChange = i => e => {
    let settings = {...this.state.settings};
    if(i === "desktop"){
      settings.desktop = !settings.desktop
      this.showNotif()
    }
    this.setState({ settings })
  }

  handleSaveChanges = () => {
    const { uid, settings } = this.state;

    db.ref(`users/${uid}/settings`)
    .set(settings)
  }

  render() {
    const { classes, className, ...rest } = this.props;

    let { desktop, textNotif, textMessage, emailNotif, emailMessage } = this.state.settings;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            classes={{ subtitle: classes.subtitle }}
            subtitle="Manage the notifications"
            title="Notifications"
          />
        </PortletHeader>
        <PortletContent noPadding>
          <form className={classes.form}>
            <div className={classes.group}>
              <Typography
                className={classes.groupLabel}
                variant="h6"
              >
                Notifications
              </Typography>
              <div className={classes.field}>
                <Checkbox
                  color="primary"
                  checked={desktop}
                  onChange={this.handleChange("desktop")}
                />
                <div>
                  <Typography variant="body1">Desktop Notifications</Typography>
                  <Typography variant="caption">
                    Get notifications for new chats & more
                  </Typography>
                </div>
              </div>
              <div className={classes.field}>
                <Checkbox
                  color="primary"
                  checked={textNotif}
                />
                <div>
                  <Typography variant="body1">Text Messages</Typography>
                </div>
              </div>
              <div className={classes.field}>
                <Checkbox
                  color="primary"
                  checked={emailNotif}
                  />
                <div>
                  <Typography variant="body1">Email</Typography>
                </div>
              </div>
            </div>
            <div className={classes.group}>
              <Typography
                className={classes.groupLabel}
                variant="h6"
              >
                Messages
              </Typography>
              <div className={classes.field}>
                <Checkbox
                  color="primary"
                  checked={emailMessage}
                  />
                <div>
                  <Typography variant="body1">Email</Typography>
                </div>
              </div>
              <div className={classes.field}>
                <Checkbox
                  color="primary"
                  checked={textMessage}
                  />
                <div>
                  <Typography variant="body1">Push Notifications</Typography>
                </div>
              </div>
            </div>
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            variant="outlined"
            onClick={this.handleSaveChanges}
          >
            Save
          </Button>
        </PortletFooter>
        <ReactNotifications
          onRef={ref => (this.n = ref)}
          title={`Brower Notifications ${!desktop ? "Enabled" : "Disabled"}`}
          body="Thank you"
          icon="https://firebasestorage.googleapis.com/v0/b/rtr-dash-demo-221116.appspot.com/o/images%2Fd516bf01-cefc-4b4e-a305-e4b1d47b2572.png?alt=media&token=1bfdeadf-63df-4e95-8c8d-ecc9b2855505"
          timeout="2000"
          onClick={(e) => console.log(e)}
        />
      </Portlet>
    );
  }
}

Notifications.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Notifications);
