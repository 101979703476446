import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Chip } from '@material-ui/core';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

import { auth, db } from '../../../../database/firebase';

const useStyles = makeStyles(theme => ({
  root: {},
  form: {
    paddingTop: '30px'
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  portletFooter: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}));

export default function POCSettings({ className, ...rest }){
  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);

  const [bID, setBID] = useState('');
  const [service, setService] = useState('');

  const [hasChange, setHasChange] = useState(false);

  const [services, setServices] = useState([]);

  useEffect(() => {
    getServices()
  },[])

  const getServices = () => {
    let services = []
    auth.onAuthStateChanged((user) => {
      if(user){
        db.ref(`users/${user.uid}/bID`)
        .once('value', (snap) => {
          let bID = localStorage.getItem('rtc_bid') || snap.val()[0];
          setBID(bID)
          db.ref(`companies/${bID}/services`)
          .once('value', (snap) => {
            snap.forEach((n) => {
              let x = n.val();
              let key = n.key;
              let service = x.service;
              services.push({ key, service })
            })
          })
          .then(() => {
            setServices(services)
          })
        })
      }
    })
  };

  const handleChange = (e) => {
    let service = e.target.value;
    setService(service);
    if(service.length > 0){
      setHasChange(true);
    } else {
      setHasChange(false);
    }
  }

  const handleAddService = () => {
    db.ref(`companies/${bID}/services`)
    .push({ service })
    .then(() => setService(''))
    .then(() => getServices())
  }

  const handleDelete = (key) => {
    db.ref(`companies/${bID}/services/${key}`)
    .remove()
    .then(() => getServices())
  }

  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <PortletHeader>
        <PortletLabel title="Service Options" />
      </PortletHeader>
      <PortletContent style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: 300, paddingRight: 20 }}>
          <h4 style={{ opacity: '0.8' }}>Add Service</h4>
          <div style={{ padding: '8px 0' }} />
          <TextField
            variant="outlined"
            margin="dense"
            fullWidth
            placeholder="Enter Service Name.."
            onChange={handleChange}
            />
        </div>
        <div style={{ padding: 5, display: 'flex', flexWrap: 'wrap' }}>
          {services.map(({ service, key }) => (
            <div style={{ padding: 5 }} key={key}>
              <Chip label={service} variant="outlined" color="primary" onDelete={() => handleDelete(key)} />
            </div>
          ))}
        </div>
      </PortletContent>
      <PortletFooter className={classes.portletFooter}>
        <Button
          disabled={service.length < 1}
          color="primary"
          variant={hasChange ? "contained" : "outlined"}
          onClick={handleAddService}>
          Add
        </Button>
      </PortletFooter>
    </Portlet>
  );
}
