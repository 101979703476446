export const columns = [
 {
  name: "name",
  label: "Name",
  options: {
   filter: false,
   sort: true,
  }
 },
 {
  name: "type",
  label: "Type",
  options: {
   filter: true,
   sort: false,
  }
 },
 {
  name: "leadType",
  label: "Lead / Customer",
  options: {
   filter: true,
   sort: false,
  }
 },
 {
  name: "number",
  label: "Phone Number",
  options: {
   filter: false,
   sort: false,
  }
 },
 {
  name: "status",
  label: "Status",
  options: {
   filter: true,
   sort: false,
  }
 },
 {
  name: "date",
  label: "Date Added",
  options: {
   filter: true,
   sort: false,
  }
 },
];
