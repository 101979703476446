import React from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';

export default function Card({ checked, label, styles, handleChange, startHour, onStartChange, onEndChange, endHour }){
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FormControlLabel
        control={
          <Switch color="primary" checked={checked} onChange={handleChange} />
        }
        label={label}
      />
    <div style={styles}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div>
          <KeyboardTimePicker
            margin="normal"
            id="time-picker"
            label="Open"
            disabled={!checked}
            value={startHour}
            onChange={onStartChange}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />
        </div>
        <div style={{ paddingLeft: 20 }}>
          <KeyboardTimePicker
            margin="normal"
            id="time-picker"
            label="Close"
            value={endHour}
            disabled={!checked}
            onChange={onEndChange}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />
        </div>
        </MuiPickersUtilsProvider>
      </div>
    </div>
  )
}
