import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { auth, db } from '../../../../database/firebase';
import {
  LaptopMac as LaptopMacIcon,
  PhoneIphone as PhoneIphoneIcon,
  TabletMac as TabletMacIcon
} from '@material-ui/icons';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent
} from 'components';
import palette from 'theme/palette';
import { options } from './chart';
import styles from './styles';

class DevicesChart extends Component {

  state = {
    bID: '',
    desktopPercentage: '',
    tabletPercentage: '',
    phonePercentage: '',
    updated: false,
    data: {
      datasets: [
        {
          data: [0, 0, 0],
          backgroundColor: [
            palette.primary.main,
            palette.danger.main,
            palette.warning.main
          ],
          borderWidth: 8,
          borderColor: palette.common.white,
          hoverBorderColor: palette.common.white
        }
      ],
      labels: ['Desktop', 'Tablet', 'Mobile']
    }
  }

  componentDidMount(){
    let {data} = this.state;
    auth.onAuthStateChanged((user) => {
      db.ref(`users/${user.uid}/bID`)
      .once('value', (snap) => {
        let bID = localStorage.getItem('rtc_bid') || snap.val()[0];
        this.setState({ bID })
      })
      .then(() => {
        db.ref(`companies/${this.state.bID}/devices`)
        .once('value', (snap) => {
          if(snap.val()){
            let d = snap.val().desktop || 0;
            let m = snap.val().mobile || 0;
            let t = snap.val().tablet || 0;

            let total = d + m + t;

            let desktopPercentage = ((d / total) * 100).toFixed(0);
            let tabletPercentage = ((t / total) * 100).toFixed(0);
            let phonePercentage = ((m / total) * 100).toFixed(0);

            data.datasets[0].data[0] = d;
            data.datasets[0].data[1] = t;
            data.datasets[0].data[2] = m;
            this.setState({ desktopPercentage, tabletPercentage, total, phonePercentage, updated: true })
          } else {
            this.setState({ desktopPercentage: 0, tabletPercentage: 0, total: 0, phonePercentage: 0, updated: true })
          }
        })
      })
    })
  }

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    const { desktopPercentage,
            tabletPercentage,
            phonePercentage,
            data,
            total,
            updated } = this.state;

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader noDivider>
          <PortletLabel
            title="Users by device"
            subtitle={`${total || 0} in total`}
            classes={{ subtitle: classes.subtitle }}
             />
          <PortletToolbar>
          </PortletToolbar>
        </PortletHeader>
        <PortletContent>
          <div className={classes.chartWrapper}>
            {
              updated &&
              <Doughnut
                data={data}
                options={options}
              />
            }
          </div>
          <div className={classes.stats}>
            <div className={classes.device}>
              <LaptopMacIcon className={classes.deviceIcon} />
              <Typography variant="body1">Desktop</Typography>
              <Typography
                style={{ color: palette.primary.main }}
                variant="h2"
              >
                {desktopPercentage}%
              </Typography>
            </div>
            <div className={classes.device}>
              <TabletMacIcon className={classes.deviceIcon} />
              <Typography variant="body1">Tablet</Typography>
              <Typography
                style={{ color: palette.danger.main }}
                variant="h2"
              >
                {tabletPercentage}%
              </Typography>
            </div>
            <div className={classes.device}>
              <PhoneIphoneIcon className={classes.deviceIcon} />
              <Typography variant="body1">Mobile</Typography>
              <Typography
                style={{ color: palette.warning.main }}
                variant="h2"
              >
                {phonePercentage}%
              </Typography>
            </div>
          </div>
        </PortletContent>
      </Portlet>
    );
  }
}

DevicesChart.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(DevicesChart);
