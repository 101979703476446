import React, { Component } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Button, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

import { auth, db } from '../../../../database/firebase';
import ReactNotifications from 'react-browser-notifications';

const styles = (theme => ({
  root: {},
  form: {
    paddingTop: '30px'
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  portletFooter: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}));

class POCSettings extends Component {
  state = {
    email: '',
    number: '',
    firstNum: '',
    firstEmail: '',
    desktopNotifications: false,
    hasChange: false
  }

  componentDidMount(){
    this.getData();
  }

  getData = () => {
    auth.onAuthStateChanged((user) => {
      if(user){
        db.ref(`users/${user.uid}/bID`)
        .once('value', (snap) => {
          let bID = localStorage.getItem('rtc_bid') || snap.val()[0];
          db.ref(`companies/${bID}`)
          .once('value', (snap) => {
            let x = snap.val();
            let number = x.pocNumber;
            let email = x.pocEmail;
            let desktopNotifications = x.desktopNotifications;
            this.setState({ bID, email, number, desktopNotifications, firstNum: number, firstEmail: email })
          })
        })
      }
    })
  };

  handleNumberChange = (e) => {
    const { firstNum } = this.state;
    let number = e.target.value;
    this.setState({ number })
    if(number !== firstNum){
      this.setState({ hasChange: true })
    } else {
      this.setState({ hasChange: false })
    }

  }

  handleEmailChange = (e) => {
    const { firstEmail } = this.state;
    let email = e.target.value;
    this.setState({ email })
    if(email !== firstEmail){
      this.setState({ hasChange: true })
    } else {
      this.setState({ hasChange: false })
    }
  }

  handleSaveChanges = () => {
    const { number, email, bID } = this.state;
    db.ref(`companies/${bID}`)
    .update({
      pocNumber: number && number,
      pocEmail: email && email
    })
    .then(() => this.setState({ hasChange: true }))
  };

  handleNotifChange = e => {
    const { desktopNotifications, bID } = this.state;

    if(!desktopNotifications){
      this.setState({ desktopNotifications: true }, () => {
        db.ref(`companies/${bID}/desktopNotifications`)
        .set(true)
      })
    } else {
      this.setState({ desktopNotifications: false }, () => {
        db.ref(`companies/${bID}/desktopNotifications`)
        .set(false)
      })
    }
    this.n.supported() && this.n.show()
  }

  render(){

    const { classes, className, ...rest } = this.props;

    const { number, email, desktopNotifications, hasChange } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel title="Contact Options" />
        </PortletHeader>
        <PortletContent style={{ display: 'flex', justifyContent: 'space-around' }}>
          <div>
            <h4 style={{ opacity: '0.8' }}>Point of Contact (Text)</h4>
            <div style={{ padding: '8px 0' }} />
            <TextField
              placeholder="Phone Number"
              margin="dense"
              value={number}
              onChange={this.handleNumberChange}
              variant="outlined"
              />
          </div>
          <div style={{ width: 'calc(100% - 220px)' }}>
            <h4 style={{ opacity: '0.8' }}>Point of Contact (Email)</h4>
            <div style={{ padding: '8px 0' }} />
            <TextField
              placeholder="Email Address"
              margin="dense"
              fullWidth
              value={email}
              onChange={this.handleEmailChange}
              variant="outlined"
              />
          </div>
        </PortletContent>
        <div style={{ padding: 20, paddingLeft: 35 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={desktopNotifications}
                onChange={this.handleNotifChange}/>
            }
            label="Desktop Notifications"
          />
        </div>
        <ReactNotifications
          onRef={ref => (this.n = ref)}
          title={`Brower Notifications ${!desktopNotifications ? "Enabled" : "Disabled"}`}
          body="Preference saved"
          icon="https://firebasestorage.googleapis.com/v0/b/rtr-dash-demo-221116.appspot.com/o/images%2Fd516bf01-cefc-4b4e-a305-e4b1d47b2572.png?alt=media&token=1bfdeadf-63df-4e95-8c8d-ecc9b2855505"
          timeout="7000"
          onClick={(e) => console.log(e)}
        />
        <PortletFooter className={classes.portletFooter}>
          <Button
            disabled={!hasChange}
            color="primary"
            variant={hasChange ? "contained" : "outlined"}
            onClick={this.handleSaveChanges}>
            Save
          </Button>
        </PortletFooter>
      </Portlet>
    )
  }
}

export default withStyles(styles)(POCSettings)
