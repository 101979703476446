import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { withStyles } from '@material-ui/core';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent,
  PortletFooter
} from 'components';
import { db } from '../../../../database/firebase';
import { options, data } from './chart';
import styles from './styles';
import moment from 'moment';

class SalesChart extends Component {

  state = {
    data: {},
    pageViews: [],
    leads: [],
    bID: ''
  }

  componentWillReceiveProps(n){
    if(n.bID && !this.state.bID){
      this.setState({ bID: n.bID }, () => {
        this.getData()
      })
    }
    // this.getData()
  }

  getData = () => {
    const { bID } = this.state;
    let pageViews = [], leads = []
    db.ref(`companies/${bID}/pageViews`)
    .once('value', (snap) => {
      snap.forEach((n) => {
        let time = n.val();
        pageViews.push(time)
      })
    })
    .then(() => {
      db.ref(`companies/${bID}/inbox`)
      .once('value', (snap) => {
        snap.forEach((n) => {
          let time = n.val().time;
          leads.push({ time })
        })
      })
      .then(() => this.setState({ pageViews, leads }, () => {
        this.getChartData()
      }))
    })
  }

  getChartData = () => {

    const { pageViews, leads } = this.state;

    let displayDates = [],
        viewData = [0,0,0,0,0,0],
        leadData = [0,0,0,0,0,0],
        shitData = [],
        dValues = [];

    for (var i = 0; i < 6; i++) {
      displayDates.push(moment().subtract(i,'day').format("MMM Do"))
      dValues.push(moment().subtract(i,'day').valueOf())
    }

    data.labels = displayDates.reverse();

    let dailyValues = dValues.reverse();

    let [dayOne, dayTwo, dayThree, dayFour, dayFive, daySix] = dailyValues;
    let [one, two, three, four, five, six] = viewData;
    let [lOne, lTwo, lThree, lFour, lFive, lSix] = leadData;

    //eslint-disable-next-line
    let v = pageViews.map((x) => {
      moment(x.time).isSame(dayOne, 'day') ?
      one += 1 :
      moment(x.time).isSame(dayTwo, 'day') ?
      two += 1 :
      moment(x.time).isSame(dayThree, 'day') ?
      three += 1 :
      moment(x.time).isSame(dayFour, 'day') ?
      four += 1 :
      moment(x.time).isSame(dayFive, 'day') ?
      five += 1 :
      moment(x.time).isSame(daySix, 'day') ?
      six += 1 : shitData.push(x)
    });

    Promise.all(v)
    .then(() => {
      data.datasets[0].data = [one, two, three, four, five, six]
    })

    //eslint-disable-next-line
    let l = leads.map((x) => {
      moment(x.time).isSame(dayOne, 'day') ?
      lOne += 1 :
      moment(x.time).isSame(dayTwo, 'day') ?
      lTwo += 1 :
      moment(x.time).isSame(dayThree, 'day') ?
      lThree += 1 :
      moment(x.time).isSame(dayFour, 'day') ?
      lFour += 1 :
      moment(x.time).isSame(dayFive, 'day') ?
      lFive += 1 :
      moment(x.time).isSame(daySix, 'day') ?
      lSix += 1 : shitData.push(x)
    })

    Promise.all(l)
    .then(() => {
      data.datasets[1].data = [lOne, lTwo, lThree, lFour, lFive, lSix];
    })
    .then(() => this.setState({ data }))
  }

  render() {
    const { classes, className, ...rest } = this.props;

    const { data } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader noDivider>
          <PortletLabel title="Lead capture overview" />
          <PortletToolbar>
            {/*<Button
              className={classes.dropdownButton}
              size="small"
              variant="text"
            >
              Last 7 days <ArrowDropDownIcon />
            </Button>*/}
          </PortletToolbar>
        </PortletHeader>
        <PortletContent>
          <div className={classes.chartWrapper}>
            <Line
              data={data}
              fill={false}
              options={options}
            />
          </div>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          {/*<Button
            color="primary"
            size="small"
            variant="text"
          >
            Overview <ArrowRightIcon />
          </Button>*/}
        </PortletFooter>
      </Portlet>
    );
  }
}

SalesChart.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SalesChart);
