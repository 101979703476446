import React, { useState, useEffect } from 'react';
// import { Button } from '@material-ui/core';
import { TwitterPicker } from 'react-color';
import { firebase } from '../../../../../database/firebase';
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';

const styles = {
  colorContainer: {
    border: '1px solid lightgray',
    borderRadius: '10px',
    padding: '20px',
    height: 'fit-content'
  },
  logoContainer: {
    border: '1px solid lightgray',
    borderRadius: '10px',
    padding: '20px'
  }
}

const placeholerImage = "https://yak-ridge.com/wp-content/uploads/2019/04/image-placeholder-350x350.png"

function ColorPicker({ headerColor,bubbleColor, bID, chatImage, handleChangeBubble, handleChangeHeader }){

  const [isUploading, setIsUploading] = useState(false);
  const [logo, setLogo] = useState(chatImage);

  const handleUploadStart = () => {
    setIsUploading(true)
  }

  useEffect(() => {
    setLogo(chatImage)

  },[chatImage])

  const handleUploadSuccess = filename => {
    setIsUploading(false)

    firebase.storage()
    .ref("images").child(filename)
    .getDownloadURL()
    .then((url) => {
      setLogo(url)
      firebase.database().ref(`companies/${bID}/chatImage`)
      .set(url)
    })
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
      <div style={styles.colorContainer}>
        <h2 style={{ margin: '10px 10px 23px 10px' }}>Header Color</h2>
        <div style={{ padding: 10, display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: 200, height: 200, borderRadius: '10px', background: headerColor }} />
        </div>
        <TwitterPicker
          color={headerColor || "#2ccce4"}
          onChange={(e) => handleChangeHeader(e.hex)}
          />
      </div>
      <div style={styles.colorContainer}>
        <h2 style={{ margin: '10px 10px 23px 10px' }}>Message Color</h2>
          <div style={{ padding: 10, display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: 200, height: 200, borderRadius: '10px', background: bubbleColor }} />
          </div>
        <TwitterPicker
          color={bubbleColor || "#2ccce4"}
          onChange={(e) => handleChangeBubble(e.hex)}
          />
      </div>
      <div style={styles.logoContainer}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2 style={{ margin: '10px 10px 23px 10px' }}>Chat Logo</h2>
            <label style={{ backgroundColor: '#0767DB', color: 'white', height: 'fit-content', padding: 10, borderRadius: 4, pointer: 'cursor' }}>
              {
                !isUploading ? "Upload" : "Uploading..."
              }
              <CustomUploadButton
                hidden
                randomizeFilename
                accept="image/*"
                storageRef={firebase.storage().ref('images')}
                onUploadStart={handleUploadStart}
                onUploadSuccess={handleUploadSuccess}
                />
            </label>
        </div>
        <div>
          <img
            alt=""
            src={logo ? logo : placeholerImage}
            style={{ maxWidth: 350, minWidth: 323 }}
             />
        </div>
      </div>
    </div>
  )
}

export default ColorPicker
