import React, { useState } from 'react';
import { FormGroup } from '@material-ui/core';
import Card from './Card';
import moment from 'moment';

// mStart, mEnd, tuStart, tuEnd, wStart, wEnd, thStart, thEnd, fStart, fEnd, saStart, saEnd, suStart, suEnd

const defaultStart = moment().hour(9).startOf('hour').valueOf();
const defaultEnd = moment().hour(17).startOf('hour').valueOf();

export default function DayEditor({ monday, hourData, tuesday, wednesday, thursday, friday, sat, sun, handleChange, pHandleStartChange, pHandleEndChange }){

  //eslint-disable-next-line
  const [hours, setHours] = useState(hourData)

  const [mondayStart, setMondayStart] = useState(hours && hours.mondayStart ? hours.mondayStart : defaultStart);
  const [mondayEnd, setMondayEnd] = useState(hours && hours.mondayEnd ? hours.mondayEnd : defaultEnd);

  const [tuesdayStart, setTuesdayStart] = useState(hours && hours.tuesdayStart ? hours.tuesdayStart : defaultStart);
  const [tuesdayEnd, setTuesdayEnd] = useState(hours && hours.tuesdayEnd ? hours.tuesdayEnd : defaultEnd);

  const [wednesdayStart, setWednesdayStart] = useState(hours && hours.wednesdayStart ? hours.wednesdayStart : defaultStart);
  const [wednesdayEnd, setWednesdayEnd] = useState(hours && hours.wednesdayEnd ? hours.wednesdayEnd : defaultEnd);

  const [thursdayStart, setThursdayStart] = useState(hours && hours.thursdayStart ? hours.thursdayStart : defaultStart);
  const [thursdayEnd, setThursdayEnd] = useState(hours && hours.thursdayEnd ? hours.thursdayEnd : defaultEnd);

  const [fridayStart, setFridayStart] = useState(hours && hours.fridayStart ? hours.fridayStart : defaultStart);
  const [fridayEnd, setFridayEnd] = useState(hours && hours.fridayEnd ? hours.fridayEnd : defaultEnd);

  const [satStart, setSatStart] = useState(hours && hours.satStart ? hours.satStart : defaultStart);
  const [satEnd, setSatEnd] = useState(hours && hours.satEnd ? hours.satEnd : defaultEnd);

  const [sunStart, setSunStart] = useState(hours && hours.sunStart ? hours.sunStart : defaultStart);
  const [sunEnd, setSunEnd] = useState(hours && hours.sunEnd ? hours.sunEnd : defaultEnd);

  const handleStartChange = ({ day, time }) => {
    if(day === 'monday'){
      setMondayStart(time);
      pHandleStartChange(day, moment(time).valueOf());
    } else if (day === 'tuesday'){
      setTuesdayStart(time);
      pHandleStartChange(day, moment(time).valueOf());
    } else if (day === 'wednesday'){
      setWednesdayStart(time);
      pHandleStartChange(day, moment(time).valueOf());
    } else if (day === 'thursday'){
      setThursdayStart(time);
      pHandleStartChange(day, moment(time).valueOf());
    } else if (day === 'friday'){
      setFridayStart(time);
      pHandleStartChange(day, moment(time).valueOf());
    } else if (day === 'sat'){
      setSatStart(time);
      pHandleStartChange(day, moment(time).valueOf());
    } else if (day === 'sun'){
      setSunStart(time);
      pHandleStartChange(day, moment(time).valueOf());
    }
  }

  const handleEndChange = ({ day, time }) => {
    if(day === 'monday'){
      setMondayEnd(time);
      pHandleEndChange(day, moment(time).valueOf());
    } else if (day === 'tuesday'){
      setTuesdayEnd(time);
      pHandleEndChange(day, moment(time).valueOf());
    } else if (day === 'wednesday'){
      setWednesdayEnd(time);
      pHandleEndChange(day, moment(time).valueOf());
    } else if (day === 'thursday'){
      setThursdayEnd(time);
      pHandleEndChange(day, moment(time).valueOf());
    } else if (day === 'friday'){
      setFridayEnd(time);
      pHandleEndChange(day, moment(time).valueOf());
    } else if (day === 'sat'){
      setSatEnd(time);
      pHandleEndChange(day, moment(time).valueOf());
    } else if (day === 'sun'){
      setSunEnd(time);
      pHandleEndChange(day, moment(time).valueOf());
    }
  }

  const data = [
    {
      label: 'Monday',
      checked: monday,
      startHour: mondayStart,
      endHour: mondayEnd,
      handleChange: () => handleChange('monday'),
      onStartChange: (time) => handleStartChange({ day: 'monday', time }),
      onEndChange: (time) => handleEndChange({ day: 'monday', time }),
      styles: {
        paddingLeft: 40,
        display: 'flex'
      }
    },
    {
      label: 'Tuesday',
      checked: tuesday,
      startHour: tuesdayStart,
      endHour: tuesdayEnd,
      handleChange: () => handleChange('tuesday'),
      onStartChange: (time) => handleStartChange({ day: 'tuesday', time }),
      onEndChange: (time) => handleEndChange({ day: 'tuesday', time }),
      styles: {
        paddingLeft: 40,
        display: 'flex'
      }
    },
    {
      label: 'Wednesday',
      checked: wednesday,
      startHour: wednesdayStart,
      endHour: wednesdayEnd,
      handleChange: () => handleChange('wednesday'),
      onStartChange: (time) => handleStartChange({ day: 'wednesday', time }),
      onEndChange: (time) => handleEndChange({ day: 'wednesday', time }),
      styles: {
        paddingLeft: 22,
        display: 'flex'
      }
    },
    {
      label: 'Thursday',
      checked: thursday,
      startHour: thursdayStart,
      endHour: thursdayEnd,
      handleChange: () => handleChange('thursday'),
      onStartChange: (time) => handleStartChange({ day: 'thursday', time }),
      onEndChange: (time) => handleEndChange({ day: 'thursday', time }),
      styles: {
        paddingLeft: 36,
        display: 'flex'
      }
    },
    {
      label: 'Friday',
      checked: friday,
      startHour: fridayStart,
      endHour: fridayEnd,
      handleChange: () => handleChange('friday'),
      onStartChange: (time) => handleStartChange({ day: 'friday', time }),
      onEndChange: (time) => handleEndChange({ day: 'friday', time }),
      styles: {
        paddingLeft: 56,
        display: 'flex'
      }
    },
    {
      label: 'Saturday',
      checked: sat,
      startHour: satStart,
      endHour: satEnd,
      handleChange: () => handleChange('sat'),
      onStartChange: (time) => handleStartChange({ day: 'sat', time }),
      onEndChange: (time) => handleEndChange({ day: 'sat', time }),
      styles: {
        paddingLeft: 40,
        display: 'flex'
      }
    },
    {
      label: 'Sunday',
      checked: sun,
      startHour: sunStart,
      endHour: sunEnd,
      handleChange: () => handleChange('sun'),
      onStartChange: (time) => handleStartChange({ day: 'sun', time }),
      onEndChange: (time) => handleEndChange({ day: 'sun', time }),
      styles: {
        paddingLeft: 48,
        display: 'flex'
      }
    },
  ]

  return (
    <div>
      <FormGroup style={{ justifyContent: 'space-evenly' }} column>
        {data.map(({ label, checked, startHour, endHour, handleChange, onStartChange, onEndChange, styles },i) => (
          <Card
            key={i}
            label={label}
            checked={checked}
            startHour={startHour}
            endHour={endHour}
            handleChange={handleChange}
            onStartChange={onStartChange}
            onEndChange={onEndChange}
            styles={styles}
             />
        ))}
      </FormGroup>
    </div>
  )
}
