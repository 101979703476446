import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { CircularProgress, Typography } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { UsersToolbar, UsersTable } from './components';
import { auth, db } from '../../database/firebase';
import styles from './style';

class UserList extends Component {

  state = {
    isLoading: true,
    limit: 10,
    users: [],
    baseData: [],
    selectedUsers: [],
    error: null,
    bID: ''
  };

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    auth.onAuthStateChanged((user) => {
      db.ref(`users/${user.uid}/bID`)
      .once('value', (snap) => {
        let bID = localStorage.getItem('rtc_bid') || snap.val()[0];
        this.setState({ bID })
      })
      .then(() => {
        let users = [], baseData = []
        db.ref(`companies/${this.state.bID}/employees`)
        .once('value', (snap) => {
          snap.forEach((x) => {
            let n = x.val()
            let key = x.key;
            let avatar = n.avatar;
            let email = n.email;
            let phone = n.phone;
            let name = n.name;
            let r = Math.floor(Math.random() * (40 - 10) + 10)
            let responseTime = `0:${r}`
            users.push({ avatar, email, phone, name, key, responseTime })
            baseData.push({ avatar, email, phone, name, key, responseTime })
          })
        })
        .then(() => this.setState({ isLoading: false, users, baseData }))
      })
    })
  }

  refreshData = () => {
    this.setState({ isLoading: true, users: [], baseData: [] }, () => {
      this.getData()
    })
  }

  onChange = (e) => {
    let text = e.target.value;
    let users = this.state.baseData.filter((x) => x.name.toLowerCase().includes(text.toLowerCase()))
    this.setState({ users })
  }

  handleSelect = selectedUsers => {
    this.setState({ selectedUsers });
  };

  renderUsers() {
    const { classes } = this.props;
    const { isLoading, users, error } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    if (error) {
      return <Typography variant="h6">{error}</Typography>;
    }

    if (users.length === 0) {
      return <Typography variant="h6">There are no users</Typography>;
    }

    return (
      <UsersTable
        onSelect={this.handleSelect}
        users={users}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const { selectedUsers, bID } = this.state;

    return (
      <DashboardLayout title="Users">
        <div className={classes.root}>
          <UsersToolbar
            bID={bID}
            refreshData={this.refreshData}
            onChange={this.onChange}
            selectedUsers={selectedUsers} />
          <div className={classes.content}>{this.renderUsers()}</div>
        </div>
      </DashboardLayout>
    );
  }
}

UserList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UserList);
