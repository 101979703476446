import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function StatusSelect({ handleChangeStatus }){

  const classes = useStyles()

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState('');

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  const handleChange = (e) => {
    let status = e.target.value
    setStatus(status);
    handleChangeStatus(status);
  }

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="demo-controlled-open-select">Status</InputLabel>
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={status}
          onChange={handleChange}
          inputProps={{
            name: 'age',
            id: 'demo-controlled-open-select',
          }}
        >
          <MenuItem value={"Cold"}>Cold</MenuItem>
          <MenuItem value={"Warm"}>Warm</MenuItem>
          <MenuItem value={"Hot"}>Hot</MenuItem>
          <MenuItem value={"Unassigned"}>Unassigned</MenuItem>
        </Select>
      </FormControl>
    </div>
  )
};

function TypeSelect({ handleChangeType }){

  const classes = useStyles()

  const [open, setOpen] = useState(false);
  const [type, setType] = useState('');

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  const handleChange = (e) => {
    let type = e.target.value
    setType(type);
    handleChangeType(type);
  }

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="demo-controlled-open-select">Type</InputLabel>
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={type}
          onChange={handleChange}
          inputProps={{
            name: 'age',
            id: 'demo-controlled-open-select',
          }}
        >
          <MenuItem value={"Lead"}>Lead</MenuItem>
          <MenuItem value={"Customer"}>Customer</MenuItem>
        </Select>
      </FormControl>
    </div>
  )
};

function TemplateSelect({ handleSetTemplate, templates }){

  const classes = useStyles()

  const [open, setOpen] = useState(false);
  const [template, setTemplate] = useState('');

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  const handleChange = (e) => {
    let template = e.target.value
    console.log(template);
    setTemplate(template);
    handleSetTemplate(template);
  }

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="demo-controlled-open-select">Template</InputLabel>
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={template}
          onChange={handleChange}
          inputProps={{
            name: 'age',
            id: 'demo-controlled-open-select',
          }}
        >
          {
            templates.length > 0 &&
            templates.map(({ title, body, key }) => <MenuItem key={key} value={body}>{title}</MenuItem>)
          }
          {
            templates.length === 0 &&
            <MenuItem value="">None</MenuItem>
          }
        </Select>
      </FormControl>
    </div>
  )
};

export { StatusSelect, TypeSelect, TemplateSelect }
