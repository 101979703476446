import React from 'react';
import MUIDataTable from "mui-datatables";
import { columns } from './columns';

export default function LeadTable({ data, options }){

  return (
    <MUIDataTable
      title={"Leads / Customers"}
      data={data}
      columns={columns}
      options={options}
    />
  )
}
