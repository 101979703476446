import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Paper } from 'components';
import styles from './styles';

class Budget extends Component {
  render() {
    const { classes, className, leads, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="body2"
            >
              LEADS THIS MONTH
            </Typography>
            <Typography
              className={classes.value}
              variant="h3"
            >
              {leads}
            </Typography>
          </div>
          <div className={classes.iconWrapper}>
            <i style={{ color: 'white', fontSize: '30px' }} className="fas fa-user-tag" />
          </div>
        </div>
        {/*<div className={classes.footer}>
          <Typography
            className={classes.difference}
            variant="body2"
          >
            <ArrowDownwardIcon />
            12%
          </Typography>
          <Typography
            className={classes.caption}
            variant="caption"
          >
            Since last month
          </Typography>
        </div>*/}
      </Paper>
    );
  }
}

Budget.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Budget);
