import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TextField } from '@material-ui/core';
import { db } from '../../../database/firebase';
import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ bID, open, handleClose }) {

  const [calID, setCalID] = useState('');

  const [showInput, setShowInput] = useState(false);
  const [showHelp, setShowHelp] = useState(false);

  const handleShowInput = () => {
    setShowInput(true)
  }

  const handleSignIn = () => {
    db.ref(`companies/${bID}/calID`)
    .set(calID)
    .then(() => {
      axios({
        method: "POST",
        url: "https://backend.realtimechat.com/get-url"
      })
      .then(({ data }) => {
        window.location.replace(data)
      })
    })
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Allow access to your Google Calendar"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {!showInput && <h3>Let customers schedule appointments, and get instant notifications by allowing Real Time Chat access your Google Calendar!</h3>}
            {showInput &&
              <div>
                <h3>Enter your Google Calendar ID</h3>
                <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setShowHelp(!showHelp)}>
                  {showHelp ? "Hide" : "Show"} Help
                </span>
              </div>
              }
            {
              showHelp &&
              <ul>
                <li>Navigate to your Google Calendar.</li>
                <li>Hover over the calendar you want to integrate and select the options icon.</li>
                <li>Select the "Settings and sharing" option.</li>
                <li>Scroll down until you see the "Intergrate Calendar", your Calendar ID will be right underneath!</li>
              </ul>
            }
            {
              showInput &&
              <div style={{ paddingTop: 20 }}>
                <TextField
                  placeholder="Calendar ID"
                  fullWidth
                  onChange={(e) => setCalID(e.target.value)}
                  />
              </div>
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={!showInput ? handleShowInput : handleSignIn}
            disabled={!calID && showInput}
            color="primary">
            {!showInput ? "Start" : "Next"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
