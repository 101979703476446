import React from 'react';
import { FormGroup, FormControlLabel, Switch } from '@material-ui/core';

export default function Chat({ chat, text, service, review, handleChange }){
  return (
    <FormGroup style={{ justifyContent: 'space-evenly' }} row>
      <FormControlLabel
        control={
          <Switch color="secondary" checked={chat} onChange={() => handleChange('chat')} />
        }
        label="Live Chat"
      />
      <FormControlLabel
        control={
          <Switch color="secondary" checked={service} onChange={() => handleChange('service')} />
        }
        label="Schedule Service"
      />
      <FormControlLabel
        control={
          <Switch color="secondary" checked={text} onChange={() => handleChange('text')} />
        }
        label="Text Us"
      />
      <FormControlLabel
        control={
          <Switch color="secondary" checked={review} onChange={() => handleChange('review')} />
        }
        label="Leave a Review"
      />
    </FormGroup>
  )
}
