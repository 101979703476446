import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import Success from '../../components/Snackbar/Success';
import { ChatOptions,
         POCSettings,
         WelcomeOptions,
         AssignChat,
         Install,
         OfflineOptions,
         MultipleChat,
         ServiceOptions } from './components';

import { auth, db } from '../../database/firebase';

const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  }
});

class Settings extends Component {

  state = {
    user: '',
    bID: '',
    welcomeMessage: '',
    endingMessage: '',
    bubbleMessage: '',
    companyName: '',
    newOpenTimeout: null,
    success: false,
    hadChange: false
  }

  componentDidMount(){
    auth.onAuthStateChanged((user) => {
      this.setState({ user: user.uid })
      db.ref(`users/${user.uid}/bID`)
      .once('value', (snap) => {
        let bID = localStorage.getItem('rtc_bid') || snap.val()[0];
        this.setState({ bID })
      })
      .then(() => {
        const { bID } = this.state;
        db.ref(`companies/${bID}`)
        .once('value', (snap) => {
          let x = snap.val();
          const companyName = x.name;
          const openTimeout = x.openTimeout / 1000;
          const headerColor = x.headerColor;
          const bubbleColor = x.bubbleColor;
          const welcomeMessage = x.welcomeMessage;
          const endingMessage = x.endingMessage;
          const bubbleMessage = x.bubbleMessage;
          const chatImage = x.chatImage;
          const pocEmail = x.pocEmail;
          const pocNumber = x.pocNumber;

          this.setState({ headerColor, pocNumber, pocEmail, bubbleColor, companyName, openTimeout, chatImage, welcomeMessage, bubbleMessage, endingMessage })
        })
      })
    })
  };

  componentDidUpdate(useless, nextState){
    if(!this.state.hasChange){
      // nextState !== this.state && console.log('has change');
      if(nextState.bubbleColor || nextState.companyName || nextState.welcomeMessage || nextState.endingMessage || nextState.bubbleMessage){
        this.setState({ hasChange: true })
      }
    }
  }

  handleChangeHeader = headerColor => this.setState({ headerColor })

  handleChangeBubble = bubbleColor => {
    this.setState({ bubbleColor });
  };

  saveChanges = () => {
    const { bID, headerColor, companyName, newOpenTimeout, openTimeout, bubbleColor, bubbleMessage, welcomeMessage, endingMessage } = this.state;
    let timeout = null;
    if (newOpenTimeout !== null) {
      timeout = newOpenTimeout * 1000;
    } else {
      timeout = openTimeout && openTimeout !== null ? openTimeout * 1000 : null;
    }

    db.ref(`companies/${bID}`)
    .update({
      headerColor,
      bubbleColor,
      endingMessage,
      name: companyName,
      bubbleMessage,
      welcomeMessage,
      openTimeout: timeout
    })
    .then(() => this.setState({ success: true, hasChange: false }))

  }

  render() {
    const { classes } = this.props;

    const { bID,
            headerColor,
            bubbleColor,
            companyName,
            pocEmail,
            pocNumber,
            hasChange,
            chatImage,
            openTimeout,
            newOpenTimeout,
            bubbleMessage,
            welcomeMessage,
            endingMessage,
            success } = this.state;

    return (
      <DashboardLayout title="Settings">
        <div className={classes.root}>
          <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <POCSettings
                  bID={bID}
                  pocEmail={pocEmail}
                  pocNumber={pocNumber}
                  showSuccess={() => this.setState({ success: true })}
                  />
              </Grid>
              <Grid item xs={12} md={6}>
                <ServiceOptions />
              </Grid>
              <Grid item xs={12} md={6}>
                <WelcomeOptions />
              </Grid>
              <Grid item xs={12} md={6}>
                <Install />
              </Grid>
              <Grid item xs={12} md={6}>
                <AssignChat />
              </Grid>
              <Grid item xs={12} md={6}>
                <MultipleChat />
              </Grid>
              <Grid item xs={12} md={12}>
                <OfflineOptions bID={this.state.bID}/>
              </Grid>
            <Grid item md={12} xs={12}>
              <ChatOptions
                headerColor={headerColor}
                bubbleColor={bubbleColor}
                bubbleMessage={bubbleMessage}
                companyName={companyName}
                welcomeMessage={welcomeMessage}
                endingMessage={endingMessage}
                openTimeout={(newOpenTimeout !== null) ? newOpenTimeout : openTimeout}
                hasChange={hasChange}
                chatImage={chatImage}
                saveChanges={this.saveChanges}
                handleChangeHeader={this.handleChangeHeader}
                handleChangeBubble={this.handleChangeBubble}
                handleTimeoutChange={(e) => this.setState({ newOpenTimeout: e.target.value })}
                handleChangeWelcome={(e) => this.setState({ welcomeMessage: e.target.value })}
                handleChangeEnding={(e) => this.setState({ endingMessage: e.target.value })}
                handleChangeBubbleMessage={(e) => this.setState({ bubbleMessage: e.target.value })}
                handleChangeCompany={(e) => this.setState({ companyName: e.target.value })}
                />
            </Grid>
          </Grid>
        </div>
        {success &&
          <Success
            open={success}
            message="Sucessfully saved changes!"
            setOpen={() => this.setState({ success: false })}
            />}
      </DashboardLayout>
    );
  }
}

Settings.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Settings);
