import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { auth, db } from '../../../../database/firebase';
import {
  Typography,
  Avatar,
  Button,
  CircularProgress
} from '@material-ui/core';
import Warning from '../../../../components/Snackbar/Warning';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';
import moment from 'moment';
import styles from './styles';
import './styles.css';



class ProductList extends Component {

  state = {
    isLoading: false,
    limit: 4,
    products: [],
    users: [],
    feedData: [],
    productsTotal: 0,
    error: false,
    bID: ''
  };

  componentWillMount() {
    auth.onAuthStateChanged((user) => {
      db.ref(`users/${user.uid}/bID`)
      .once('value', (snap) => {
        let bID = localStorage.getItem('rtc_bid') || snap.val()[0];
        this.setState({ bID })
      })
      .then(() => this.getData())
    })
  }

  getData = () => {
    let { bID } = this.state;
    let feedData = [];

    db.ref(`companies/${bID}/liveData`)
    .on('child_added', (snap) => {
      const key = snap.key;
      const time = snap.val().time;
      const city = snap.val().city;

      feedData.push({ key, city, time });
      feedData.sort((a,b) => a.time < b.time ? 1 : -1);

      this.setState({ feedData })

    });

    db.ref(`companies/${bID}/liveData`)
    .on('child_removed', (snap) => {
      let itemToDelete = snap.key;

      feedData.filter((x,i) => {
        return x.key === itemToDelete && feedData.splice(i,1);
      });

      this.setState({ feedData })
    })
  }

  renderProducts() {
    const { classes } = this.props;
    let { isLoading, /*feedData*/ } = this.state;

    let data = [];

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    if (data.length === 0) {
      return (
        <Typography style={{ textAlign: 'center', fontSize: 20, opacity: '0.7', paddingTop: 30 }} variant="h6">Coming soon!</Typography>
      );
    }

    return (
      <Fragment>
        {
          //eslint-disable-next-line
          data.map((x,i) => {
            return (
              <div className={i === 0 ? "newFeedItem" : "feedItem"} key={x + i} >
                <div className={classes.productImageWrapper}>
                  <Avatar>
                    <i className="fas fa-map-marker-alt"/>
                  </Avatar>
                </div>
                <div className={classes.productDetails}>
                  <div>
                    <Typography
                      className={classes.productTitle}
                      variant="h5"
                    >
                      {x.city}
                    </Typography>
                    <Typography
                      className={classes.productTimestamp}
                    >
                    {`${moment(x.time).format('MMM Do, hh:mm a')}`}
                    </Typography>
                  </div>
                  <div>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => this.setState({ error: true })}
                      variant="contained">
                      Start Chat
                    </Button>
                  </div>
                </div>
              </div>
            )
        })}
      </Fragment>
    );
  }

  render() {
    const { classes, className, ...rest } = this.props;
    const { feedData, error } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader noDivider>
          <PortletLabel
            classes={{ subtitle: classes.subtitle }}
            subtitle={`${feedData.length} currently on your website`}
            title="Users Live on Website"
          />
        </PortletHeader>
        <p style={{ paddingLeft: 25, opacity: '0.6', fontSize: 15 }}>(Desktop only)</p>
        <PortletContent className={classes.portletContent}>
          {this.renderProducts()}
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Warning
            open={error}
            message="Feature coming soon!"
            setOpen={() => this.setState({ error: false })}
            />
        </PortletFooter>
      </Portlet>
    );
  }
}

ProductList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductList);

// <div className={classes.productImageWrapper}>
//   <Avatar src={user.avatar} />
// </div>
// <div className={classes.productDetails}>
//   <div>
//     <Typography
//       className={classes.productTitle}
//       variant="h5"
//     >
//       {user.name}
//     </Typography>
//     <Typography
//       className={classes.productTimestamp}
//     >
//     {`${user.rating}%`}
//     </Typography>
//   </div>
// </div>
// <div>
//   <IconButton>
//     {/*<MoreVertIcon />*/}
//   </IconButton>
// </div>
