import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { Avatar, Typography } from '@material-ui/core';
import { Portlet, PortletContent, PortletFooter } from 'components';
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import moment from 'moment';
import styles from './styles';
import { firebase } from '../../../../database/firebase';

class AccountProfile extends Component {

  state = {
    isUploading: false
  }

  handleUploadStart = () => {
    this.setState({ isUploading: true }, () => {
      setTimeout(() => {
        this.setState({ isUploading: false })
      }, 1500)
    })
  }

  render() {
    const { classes, className, name, handleUploadSuccess, avatar, ...rest } = this.props;

    const { isUploading } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletContent>
          <div className={classes.details}>
            <div className={classes.info}>
              <Typography variant="h2">{name}</Typography>
              <Typography
                className={classes.dateText}
                variant="body1"
              >
                {moment().format('LT')}
              </Typography>
            </div>
            <Avatar
              className={classes.avatar}
              src={avatar}
            />
          </div>
          {/*<div className={classes.progressWrapper}>
            <Typography variant="body1">Profile Completeness: 95%</Typography>
            <LinearProgress
              value={95}
              variant="determinate"
            />
          </div>*/}
        </PortletContent>
        <PortletFooter style={{ paddingTop: 20, paddingBottom: 20 }}>
          <label style={{ backgroundColor: '#0767DB', color: 'white', height: 'fit-content', padding: 10, borderRadius: 4, pointer: 'cursor' }}>
            {
              !isUploading ? "Upload Picture" : "Uploading..."
            }
            <CustomUploadButton
              hidden
              randomizeFilename
              accept="image/*"
              storageRef={firebase.storage().ref('images')}
              onUploadStart={this.handleUploadStart}
              onUploadSuccess={handleUploadSuccess}
              />
          </label>
        </PortletFooter>
      </Portlet>
    );
  }
}

AccountProfile.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AccountProfile);
