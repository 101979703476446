import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import { startLogin, startTokenLogin } from '../../actions/auth';
// import { auth } from '../../database/firebase';
import config from '../../store/config';
import {
  Grid,
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core';
import styles from './styles';
import * as constant from '../../constants.js';

const store = config();

class SignIn extends Component {
  state = {
    email: '',
    password: '',
    isLoading: false,
  };

  componentDidMount(){
    let tokenCheck = window.location.pathname.split('/')[2]
    if(tokenCheck){
      store.dispatch(startTokenLogin(tokenCheck))
    }
  }

  handleSignIn = () => {
    const { email, password } = this.state;

    this.setState({ isLoading: true })

    store.dispatch(startLogin(email, password))
    .then(() => {
      if(!email.includes('@trenchlessmarketing.com') && email !== 'admin@realtimechat.com' && constant.RTM === 1){
        const url = 'https://dashboard.realtimemarketing.com/secure/'+Date.now()+'/'+encodeURIComponent(window.btoa(email))+'?redirect=rtc.index';
        window.location.replace(url);
      }
    })
    .catch((e) => {
      e.code === 'auth/wrong-password' ?
      this.setState({ error: 'Invalid Password', isLoading: false }) :
      this.setState({ error: 'No Account Found', isLoading: false })
    })

    // auth.signInWithEmailAndPassword(email, password)
    // .then((user) => {
    //   let uid = user.user.uid;
    //   db.ref(`users/${uid}/bID`)
    //   .once('value', (snap) => {
    //     let bID = snap.val()[0];
    //     db.ref(`companies/${bID}/isFirstLogin`)
    //     .once('value', (s) => {
    //       let check = s.val();
    //       if(!check){
    //         store.dispatch(startLogin(email, password))
    //         window.location.pathname = '/dashboard';
    //       } else {
    //         window.location.pathname = '/set-up'
    //       }
    //     })
    //   })
    // })
    // .catch((e) => {
    //   e.code === 'auth/wrong-password' ?
    //   this.setState({ error: 'Invalid Password', isLoading: false }) :
    //   this.setState({ error: 'No Account Found', isLoading: false })
    // })
  };

  render() {
    const { classes } = this.props;
    const {
      isLoading,
      error
    } = this.state;

    return (
      <div className={classes.root}>
        <Grid
          className={classes.grid}
          container
        >
          <Grid
            className={classes.quoteWrapper}
            item
            lg={5}
          >
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                <Typography
                  className={classes.quoteText}
                  variant="h1"
                >
                  Connect with your customers and boost conversions with <br/>
                  <span style={{ fontWeight: 'bold' }}>
                    Real Time Chat
                  </span>
                </Typography>
                <div className={classes.person}>
                  <Typography
                    className={classes.name}
                    variant="body1"
                  >

                  </Typography>
                  <Typography
                    className={classes.bio}
                    style={{ color: 'transparent' }}
                    variant="body2"
                  >
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid
            className={classes.content}
            item
            lg={7}
            xs={12}
          >
            <div className={classes.content}>
              <div className={classes.contentBody}>
                <form onSubmit={this.handleSignIn} className={classes.form}>
                  <Typography
                    className={classes.title}
                    style={{ display: 'flex' }}
                    variant="h2"
                  >
                    Sign in {error && <p style={{ paddingLeft: 20, fontSize: 15 }}>{error}</p>}
                  </Typography>
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      placeholder="Email"
                      type="email"
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      onChange={(e) => this.setState({ password: e.target.value })}
                      placeholder="Password"
                      type="password"
                      variant="outlined"
                    />
                  </div>
                  {isLoading ? (
                    <CircularProgress className={classes.progress} />
                  ) : (
                    <Button
                      className={classes.signInButton}
                      color="primary"
                      type="submit"
                      disabled={false}
                      onClick={this.handleSignIn}
                      size="large"
                      variant="contained"
                    >
                      Sign in
                    </Button>
                  )}
                  {/*<Typography
                    className={classes.signUp}
                    variant="body1"
                  >
                    Don't have an account?{' '}
                    <Link
                      className={classes.signUpUrl}
                      to="/sign-up"
                    >
                      Sign up
                    </Link>
                  </Typography>*/}
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

SignIn.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles)
)(SignIn);
