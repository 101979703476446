import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Button, TextField } from '@material-ui/core';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';
import Success from '../../../../components/Snackbar/Success';
import { db } from '../../../../database/firebase';
import styles from './styles';

class Account extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    bID: '',
    success: false
  };


  componentWillReceiveProps(n){
    this.setState({
      firstName: n.name,
      lastName: n.lastname,
      email: n.email,
      phone: n.phone,
      bID: n.bID,
    })
  };

  handleSaveChanges = () => {
    const { email, firstName, lastName, phone, bID } = this.state;
    let user = localStorage.getItem('uid')
    db.ref(`users/${user}`)
    .update({ name: firstName, email, lastName, phone })
    .then(() => {
      db.ref(`companies/${bID}/employees/${user}`)
      .update({ phone, name: firstName, email })
    })
    .then(() => this.setState({ success: true }))
    .catch((e) => {
      this.setState({ error: true })
    })
  }

  render() {
    const { classes, className, ...rest } = this.props;
    const { firstName, lastName, phone, email, success } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            classes={{ subtitle: classes.subtitle }}
            subtitle=""
            title="Profile"
          />
        </PortletHeader>
        <PortletContent style={{ display: 'flex', justifyContent: 'center' }} noPadding>
          <form
            autoComplete="off"
            noValidate
          >
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="First name"
                margin="dense"
                variant="outlined"
                value={firstName}
                onChange={(e) => this.setState({ firstName: e.target.value })}
              />
              <TextField
                className={classes.textField}
                label="Last name"
                margin="dense"
                value={lastName}
                variant="outlined"
                onChange={(e) => this.setState({ lastName: e.target.value })}
              />
            </div>
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="Phone Number"
                margin="dense"
                value={phone}
                variant="outlined"
                onChange={(e) => this.setState({ phone: e.target.value })}
              />
              <TextField
                disabled
                className={classes.textField}
                label="Email Address"
                margin="dense"
                value={email}
                variant="outlined"
                // onChange={(e) => this.setState({ email: e.target.value })}
              />
              <div style={{ marginTop: '10px', background: '#eee', borderRadius: '2px', padding: '10px' }}>
                <p style={{ fontWeight: '400', fontStyle: 'italic', fontSize: '12px' }}>
                  To change your email, please send a request to <a href="mailto:support@realtimemarketing.com">support@realtimemarketing.com</a>.
                </p>
              </div>
            </div>
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleSaveChanges}
          >
            Save details
          </Button>
        </PortletFooter>
        {success &&
          <Success
            open={success}
            message="Successfully updated account!"
            setOpen={() => this.setState({ success: false })}
            />}
      </Portlet>
    );
  }
}

Account.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Account);
