import React, { Component } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { List, ListItem } from '@material-ui/core'
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';
import style from './styles'

class MultipleChat extends Component {
  render(){

    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet
        {...rest}
        className={rootClassName}>
        <PortletHeader>
          <PortletLabel
            title="How to text with multiple chats"
          />
        </PortletHeader>
        <PortletContent>
          <List>
            <ListItem>-If you have more than one room active in your chats and assign it to an employee, they will receive a "Room ID" to text to.</ListItem>
            <ListItem>-To text into a specific chat, the room number is entered first following a colon (:) symbol</ListItem>
            <ListItem>-The response text will look like this, <strong>0: Hello!</strong></ListItem>
            <ListItem>-If the colon isn't present, the text will not be received by the customer</ListItem>
          </List>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          {/*<Button
            variant="contained"
            color="secondary"
            >
            Watch Video
          </Button>*/}
        </PortletFooter>
      </Portlet>
    )
  }
}

export default withStyles(style)(MultipleChat)
