import React from 'react';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import { chartjs } from './helpers';
import theme from './theme';
import config from './store/config';
import AppRouter from './routes/AppRouter';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';

const store = config()

export const history = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

export default function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppRouter />
      </ThemeProvider>
    </Provider>
  );
}
