import React from 'react';
import './styles.css';

const Loader = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh', background: 'white' }}>
    <div className="loader">Loading...</div>
  </div>
)

export default Loader;
