import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Paper } from 'components';
import styles from './styles';

class Users extends Component {
  render() {
    const { classes, rank, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="body2"
            >
              AVERAGE CHAT RATING
            </Typography>
            <Typography
              className={classes.value}
              variant="h3"
            >
              {`${rank} / 5`}
            </Typography>
          </div>
          <div className={classes.iconWrapper}>
            <i style={{ color: 'white', fontSize: '30px' }} className="far fa-star" />
          </div>
        </div>
      </Paper>
    );
  }
}

Users.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Users);
