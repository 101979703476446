import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { FormControlLabel, Switch } from '@material-ui/core';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';
import Snack from './Snack';
import { auth, db } from '../../../../database/firebase';

const useStyles = makeStyles(theme => ({
  root: {},
  form: {
    paddingTop: '30px'
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  portletFooter: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}));

export default function WelcomeOptions({ className, ...rest }){
  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);

  const [bID, setBID] = useState('');

  const [review, hasReview] = useState(false);
  const [chat, hasChat] = useState(false);
  const [text, hasText] = useState(false);
  const [hours, hasHours] = useState(false);
  const [service, hasService] = useState(false);
  const [open, isOpen] = useState(false);
  const [scheduleError, isScheduleError] = useState(false);
  //eslint-disable-next-line
  const [reviewError, isReviewError] = useState(false);

  useEffect(() => {
    getOptions()
  },[])

  const getOptions = () => {
    auth.onAuthStateChanged((user) => {
      if(user){
        db.ref(`users/${user.uid}/bID`)
        .once('value', (snap) => {
          let bID = localStorage.getItem('rtc_bid') || snap.val()[0];
          setBID(bID)
          db.ref(`companies/${bID}/welcomeOptions`)
          .once('value', (snap) => {
            let chat = snap.val().chat;
            let hours = snap.val().hours;
            let review = snap.val().review;
            let service = snap.val().service;
            let text = snap.val().text;
            hasChat(chat)
            hasHours(hours)
            hasReview(review)
            hasService(service)
            hasText(text)
          })
        })
      }
    })
  };

  const handleChange = val => e => {
    if(val === 'service'){
      checkForCal(() => {
        hasService(!service)
        db.ref(`companies/${bID}/welcomeOptions/service`)
        .set(!service)
      })
    } else if(val === 'hours'){
      hasHours(!hours)
      db.ref(`companies/${bID}/welcomeOptions/hours`)
      .set(!hours)
    } else if(val === 'text'){
      hasText(!text)
      db.ref(`companies/${bID}/welcomeOptions/text`)
      .set(!text)
    } else if(val === 'review'){
      checkForReview(() => {
        hasReview(!review)
        db.ref(`companies/${bID}/welcomeOptions/review`)
        .set(!review)
      })
    } else if(val === 'chat'){
      hasChat(!chat)
      db.ref(`companies/${bID}/welcomeOptions/chat`)
      .set(!chat)
    }
  };

  const checkForCal = (callback) => {
    db.ref(`companies/${bID}/calToken`)
    .once('value', (snap) => {
      if(snap.val()){
        callback()
      } else {
        isOpen(true);
        isReviewError(false);
        isScheduleError(true);
      }
    })
  };

  const checkForReview = (callback) => {
    db.ref(`companies/${bID}/rtrID`)
    .once('value', (snap) => {
      if(snap.val()){
        callback()
      } else {
        isOpen(true);
        isScheduleError(false);
        isReviewError(true);
      }
    })
  }

  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <PortletHeader>
        <PortletLabel title="Customer Options" />
      </PortletHeader>
      <PortletContent>
        <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
          <FormControlLabel
           control={
             <Switch checked={service} color="primary" onChange={handleChange('service')} />
           }
           label="Schedule Service"/>
           <FormControlLabel
            control={
              <Switch checked={text} color="primary" onChange={handleChange('text')} />
            }
            label="Text Us"/>
          <FormControlLabel
           control={
             <Switch checked={review} color="primary" onChange={handleChange('review')} />
           }
           label="Leave a Review"/>
         <FormControlLabel
          control={
            <Switch checked={chat} color="primary" onChange={handleChange('chat')} />
          }
          label="Start Chat"/>
        {/*<FormControlLabel
         control={
           <Switch checked={hours} color="primary" onChange={handleChange('hours')} />
         }
         label="What are your hours?"/>*/}
        </div>
      </PortletContent>
      <PortletFooter className={classes.portletFooter}>

      </PortletFooter>
      <Snack
        open={open}
        handleClose={() => isOpen(false)}
        message={
          scheduleError ? "You must integrate your Google Calendar!" :
          "You must be integrated with Real Time Reviews!"
        }
        />
    </Portlet>
  );
};
