import React, { useState } from 'react';
import { Paper, Button, TextField } from '@material-ui/core';
import { StatusSelect, TypeSelect, TemplateSelect } from './atoms';
import Success from '../../../../components/Snackbar/Success'
import axios from 'axios';

export default function OptionsBar({ selectedData, handleChangeStatus, handleChangeType, openNewTemplate, templates, bID }){

  const [body, setBody] = useState('');
  const [success, isSuccess] = useState(false)

  const setTemplateBody = body => {
    setBody(body);
  };

  const handleSendMessage = () => {
    if(body.length > 0){
      let numbers = [];
      selectedData.map((x) => {
        return numbers.push(x.number)
      })
      axios({
        method: "POST",
        url: 'http://localhost:5001/send-template',
        data: {
          numbers, bID, body
        }
      })
      .then(() => isSuccess(true))
      .then(() => setBody(''))
    }
  }

  return (
    <div>
      <Paper style={{ padding: "20px" }}>
        <div>
          <h3 style={{ fontSize: 15, opacity: '0.8' }}>Options</h3>
        </div>
        <div style={{ padding: '40px 0' }}>
          <hr />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <div>
            <h4>Change Status To..</h4>
            <StatusSelect
              handleChangeStatus={handleChangeStatus}
              />
          </div>
          <div>
            <h4>Change Lead / Customer..</h4>
            <TypeSelect
              handleChangeType={handleChangeType}
              />
          </div>
        </div>
        <div style={{ padding: '20px 0' }}>
          <hr />
        </div>
        <div>
          <h3 style={{ fontSize: 15, opacity: '0.8' }}>Messaging</h3>
        </div>
        <div style={{ padding: '15px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <TemplateSelect handleSetTemplate={setTemplateBody} templates={templates}/>
          <Button onClick={openNewTemplate} variant="contained" color="primary" style={{ height: 'fit-content' }}>+ Template</Button>
        </div>
        <div>
          <TextField
            fullWidth
            value={body}
            onChange={(e) => setBody(e.target.value)}
            variant="outlined"
            multiline
            rows={5}
            />
        </div>
        <div style={{ textAlign: 'end', paddingTop: 23 }}>
          <Button onClick={handleSendMessage} color="primary">
            Send
          </Button>
        </div>
      </Paper>
      <Success
        open={success}
        message="Sent!"
        handleClose={() => isSuccess(false)}
        setOpen={() => isSuccess(false)}
        />
    </div>
  )
}
