import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { AccountProfile, AccountDetails, Password } from './components';
import { firebase, db } from '../../database/firebase';

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
  }
});

class Account extends Component {
  state = {
    avatar: '',
    name: '',
    lastName: '',
    email: '',
    phone: '',
    bID: ''
  };

  // checker = () => {
  //   auth.onAuthStateChanged((user) => {
  //     if(user){
  //       db.ref(`users/${user.uid}/bID`)
  //       .once('value', (snap) => {
  //         let bID = localStorage.getItem('rtcBID')
  //          bID ? this.setState({ bID }) :
  //          this.setState({ bID: snap.val()[0] })
  //       })
  //     }
  //   })
  // }


  componentDidMount(){
    let uid = localStorage.getItem('uid')
    this.setState({ uid })
    db.ref(`users/${uid}`)
    .once('value', (snap) => {
      let x = snap.val()
      let avatar = x.avatar;
      let name = x.name;
      let lastName = x.lastName;
      let email = x.email;
      let phone = x.phone;
      let bID = localStorage.getItem('rtc_bid') || x.bID[0];
      
      this.setState({
        avatar,
        name,
        lastName,
        email,
        phone,
        bID
      })
    })
  }

  handleUploadSuccess = filename => {
    firebase.storage()
    .ref("images").child(filename)
    .getDownloadURL()
    .then((url) => {
      this.setState({ avatar: url })
      firebase.database().ref(`users/${this.state.uid}/avatar`)
      .set(url)
      .then(() => {
        firebase.database().ref(`companies/${this.state.bID}/employees/${this.state.uid}/avatar`)
        .set(url)
      })
    })
  }

  render() {
    const { classes } = this.props;

    const { avatar, name, lastName, email, phone, bID } = this.state;

    return (
      <DashboardLayout title="Account">
        <div className={classes.root}>
          <Grid style={{ display: 'flex', justifyContent: 'center' }} container spacing={4}>
            <Grid item lg={4} md={6} xl={4} xs={12}>
              <AccountProfile
                avatar={avatar}
                name={name + ' ' + lastName}
                handleUploadSuccess={this.handleUploadSuccess} />
            </Grid>
            <Grid item lg={4} md={6} xl={4} xs={12}>
              <Password />
            </Grid>
            <Grid item lg={8} md={8} xl={8} xs={12}>
              <AccountDetails
                name={name}
                lastname={lastName}
                email={email}
                phone={phone}
                bID={bID}
                />
            </Grid>
          </Grid>
        </div>
      </DashboardLayout>
    );
  }
}

Account.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Account);
