import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { Button, TextField, FormControlLabel, Switch } from '@material-ui/core';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';
import ColorPicker from './atoms/ColorPicker';
import { db } from '../../../../database/firebase';
import styles from './styles';

class ChatOptions extends Component {
  state = {
    bID: '',
    openTimeout: 0,
    autoOpen: true,
    showOpenTimeout: false
  };

  componentDidMount(){
    let uid = localStorage.getItem('uid');

    db.ref(`users/${uid}/bID`)
    .once('value', (snap) => {
      let bID = localStorage.getItem('rtc_bid') || snap.val()[0];
      this.setState({ bID })
    })
    .then(() => {
      db.ref(`companies/${this.state.bID}`)
      .once('value', (snap) => {
        let x = snap.val();
        let autoOpen = x.hasAutoPop;
        let openTimeout = x.openTimeout * 1000;
        let chatImage = x.chatImage;
        this.setState({ autoOpen, openTimeout, chatImage, showOpenTimeout: autoOpen })
      })
    })
  }

  handleChange = name => event => {
    this.setState({ ...this.state, [name]: event.target.checked });

    event.target.checked ?
    this.setState({ showOpenTimeout: true }) :
    this.setState({ showOpenTimeout: false })

    db.ref(`companies/${this.state.bID}/hasAutoPop`)
    .set(event.target.checked)
  };

  render() {
    const { classes,
            className,
            saveChanges,
            bubbleMessage,
            welcomeMessage,
            endingMessage,
            companyName,
            headerColor,
            bubbleColor,
            hasChange,
            handleChangeHeader,
            handleChangeBubble,
            handleChangeWelcome,
            handleChangeEnding,
            handleChangeCompany,
            handleChangeBubbleMessage,
            handleTimeoutChange,
            openTimeout,
            ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    const { showOpenTimeout, chatImage } = this.state;

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            title="Chat Options"
          />
        </PortletHeader>
        <PortletContent>
          <ColorPicker
            headerColor={headerColor}
            bubbleColor={bubbleColor}
            bID={this.state.bID}
            chatImage={chatImage}
            handleChangeHeader={handleChangeHeader}
            handleChangeBubble={handleChangeBubble}
            />
          <form className={classes.form}>
            <div style={{ padding: '30px 0' }}>
              <FormControlLabel
                control={
                  <Switch checked={this.state.autoOpen} onChange={this.handleChange('autoOpen')} value="autoOpen" />
                }
                label="Bubble Message Timeout"
              />
              {/* <TextField
                variant="outlined"
                label="Popup time (seconds)"
                type="number"
                placeholder={openTimeout || 0}
                disabled={!showOpenTimeout}
                onChange={handleTimeoutChange}
              /> */}
              <TextField
                label="Popup time (seconds)"
                type="number"
                placeholder={openTimeout || 0}
                disabled={!showOpenTimeout}
                onChange={handleTimeoutChange}
                InputLabelProps={{
                  shrink: true
                }}
                variant="outlined"
                value={openTimeout}
              />
            </div>
            <TextField
              className={classes.textField}
              label="Company Name"
              value={companyName}
              onChange={handleChangeCompany}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              label="Bubble Message (80 Character Max)"
              value={bubbleMessage}
              onChange={handleChangeBubbleMessage}
              inputProps={{
                maxLength: 80,
              }}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              label="Welcome Message"
              value={welcomeMessage}
              onChange={handleChangeWelcome}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              label="Ending Message"
              value={endingMessage}
              onChange={handleChangeEnding}
              variant="outlined"
            />
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            variant={hasChange ? "contained" : "outlined"}
            onClick={saveChanges}
          >
            Update
          </Button>
        </PortletFooter>
      </Portlet>
    );
  }
}

ChatOptions.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ChatOptions);
