import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

export const RedirectRoute = ({
    isAuthenticated
  }) => (
  <Route>
    isAuthenticated ?
    <Redirect to="/dashboard" />
    <Redirect to="/sign-in" />
  </Route>
)

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.uid
});

export default connect(mapStateToProps)(RedirectRoute);
