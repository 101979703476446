import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

export default function SimpleDialog({ data, open, setOpen }) {

  const handleClose = () => {
    setOpen()
  };

  const handleListItemClick = bID => {
    localStorage.setItem('rtc_bid', bID)
    setTimeout(() => {
      window.location.reload()
    }, 500)
  };

  return (
    <Dialog fullWidth width="md" onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Choose Company</DialogTitle>
      <List style={{ maxHeight: 500, overflow: 'auto' }}>
        {data && data.map(x => (
          <ListItem button onClick={() => handleListItemClick(x.bID)} key={x.bID}>
            <ListItemText primary={x.name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
