import React, { useState, useEffect } from 'react';
import { TextField, Button } from '@material-ui/core';
import { auth, db } from '../../database/firebase';
import './styles.css';

const NumberInput = ({ onChange }) => (
  <div style={{ textAlign: 'center' }}>
    <p style={{ fontSize: 20 }}>What's the best cell phone number for you?</p>
    <span style={{ fontSize: 13, color: 'gray' }}>Only used to get text notifications from chats</span>
    <div style={{ padding: 30 }}>
      <TextField
        fullWidth
        placeholder="Phone Number"
        onChange={onChange}
        />
    </div>
  </div>
);

const EmailInput = ({ onChange }) => (
  <div className="email_setup">
    <p style={{ fontSize: 20 }}>What's the best email address for you?</p>
    <span style={{ fontSize: 13, color: 'gray' }}>Only used to get notifications from chats and updates</span>
    <div style={{ padding: 30 }}>
      <TextField
        fullWidth
        placeholder="Email Address"
        onChange={onChange}
        />
    </div>
  </div>
);

const FinishedMessage = () => (
  <div style={{ textAlign: 'center', padding: 30 }}>
    <h3>You're all set!</h3>
    <p style={{ margin: 20, color: 'gray' }}>To set up your account, personalize your webchat and more visit the Settings page in your dashboard</p>
  </div>
)

export default function SetUp(){

  const [pocNumber, setNumber] = useState('');
  const [pocEmail, setEmail] = useState('');
  const [bID, setBID] = useState('');

  const [step, setStep] = useState(0);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if(!user){
        window.location.pathname = '/sign-in';
      } else {
        db.ref(`users/${user.uid}/bID`)
        .once('value', (snap) => {
          if(snap.val()){
            setBID(snap.val()[0])
          }
        })
      }
    })
  },[])

  const onClick = () => {
    if(step === 0){
      db.ref(`companies/${bID}/pocNumber`)
      .set(pocNumber)
      .then(() => setStep(step + 1))
    }
    if(step === 1){
      db.ref(`companies/${bID}/pocEmail`)
      .set(pocEmail)
      .then(() => setStep(step + 1))
    }
  };

  const goToDash = () => {
    db.ref(`companies/${bID}/isFirstLogin`)
    .set(false)
    .then(() => {
      window.location.pathname = '/dashboard';
    })
  }

  return (
    <div className="setup_container">
      <div className="welcome_container">
        <div style={{ textAlign: 'center' }}>
          <h2 style={{ opacity: '0.6', fontSize: 30 }}>Welcome to Real Time Chat!</h2>
          <p style={{ margin: '20px 0' }}>Let's get your account set up before we start.</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', padding: '25px 0' }}>
          <hr style={{ border: 'none', height: 2, width: 50, background: 'lightgray' }}/>
        </div>
        {
          step === 0 ?
          <NumberInput onChange={(e) => setNumber(e.target.value)} /> :
          step === 1 ?
          <EmailInput onChange={(e) => setEmail(e.target.value)} /> :
          <FinishedMessage />
        }
        {
          step !== 2 &&
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button onClick={() => setStep(step + 1)}>Skip</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onClick}
              disabled={!pocNumber}
              >
              Save
            </Button>
          </div>
        }
        {
          step === 2 &&
          <div style={{ padding: '15px 0', textAlign: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={goToDash}
              >
              Go To Dashboard
            </Button>
          </div>
        }
      </div>
    </div>
  )
}
