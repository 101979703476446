export default theme => ({
  root: {},
  subtitle: {
    paddingLeft: 5,
  },
  integrationItem: {
    width: 200,
    height: 200,
    padding: 20,
    border: '1px solid lightgray',
    opacity: '0.7',
    trasition: '0.2s',
    "&:hover": {
      transform: 'scale(1.2)',
      opacity: 1
    }
  },
  portletFooter: {
    display: 'flex',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
});
