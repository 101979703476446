import React from 'react';
import { withStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { Video } from './components';

const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  }
});

function Tutorials({ classes }){
  return (
    <DashboardLayout title="Tutorials & FAQ">
      <div className={classes.root}>
        <Grid
          container
          spacing={4}>
          <Grid
            item
            xs={12}
            md={5}>
            <Video />
          </Grid>
        </Grid>
      </div>
    </DashboardLayout>
  )
};

export default withStyles(styles)(Tutorials)
