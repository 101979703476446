import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { Button, TextField } from '@material-ui/core';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';
import axios from 'axios';
import Success from '../../../../components/Snackbar/Success';
import Warning from '../../../../components/Snackbar/Warning';
import { auth } from '../../../../database/firebase'
import styles from './styles';

class Password extends Component {
  state = {
    pass: '',
    confirm: '',
    uid: '',
    errorMessage: '',
    error: false,
    success: false
  };

  componentDidMount(){
    auth.onAuthStateChanged((user) => {
      user && this.setState({ uid: user.uid })
    })
  }

  handleChangePass = () => {
    const { uid, pass, confirm } = this.state;

    if(pass !== confirm){
      this.setState({ error: true, errorMessage: 'Passwords must match.' })
    } else if(pass.length < 6){
      this.setState({ error: true, errorMessage: 'Password must be 6 or more characters long.' })
    } else {
      axios({
        method: "POST",
        url: "https://backend.realtimechat.com/change-pass",
        data: {
          uid, password: pass
        }
      })
      .then((res) => {
        this.setState({ success: true, error: false })
      })
      .catch((e) => {
        console.log(e);
      })
    }
  }

  render() {
    const { classes, className, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    const { success, error, errorMessage } = this.state;

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            classes={{ subtitle: classes.subtitle }}
            subtitle=""
            title="Password"
          />
        </PortletHeader>
        <PortletContent>
          <form className={classes.form}>
            <TextField
              className={classes.textField}
              label="Password"
              name="password"
              onChange={(e) => this.setState({ pass: e.target.value })}
              type="password"
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              label="Confirm password"
              name="confirm"
              onChange={(e) => this.setState({ confirm: e.target.value })}
              type="password"
              variant="outlined"
            />
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            variant="outlined"
            onClick={this.handleChangePass}
          >
            Update
          </Button>
        </PortletFooter>
        <Success
          open={success}
          message="Password Updated!"
          setOpen={() => this.setState({ success: false })}
          />
        <Warning
          open={error}
          message={errorMessage}
          setOpen={() => this.setState({ error: false })}
          />
      </Portlet>
    );
  }
}

Password.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Password);
