import React, { Component } from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { Button, List, ListItem } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';
import style from './styles';
import { auth, db } from '../../../../database/firebase';

const chatCode = (id) => (`<div id="rtc-embed-holder"></div>
<script src="https://realtimemarketing.com/rtcembed.js"></script>
<script type="text/javascript">
RTCLIB.init(['${id}']);
</script>`);

// const chatCode = (id) => (` <div id="rtc-button--open" style="width: 80px;height: 80px;cursor:pointer;display: flex;overflow: hidden;position: fixed;bottom: -20px;right: 35px;align-items: center;flex-shrink: 0;user-select: none;border-radius: 50%;justify-content: center;" target="rtc-frame" onclick="rtcTogglePane()">
// <img style="width: 100%;height: 100%;object-fit: cover;text-align: center;" src="https://firebasestorage.googleapis.com/v0/b/realtimechat-72615.appspot.com/o/images%2FReal%20Time%20Chat%20-%20Icon%20HD.png?alt=media&token=3da73dae-e891-41d6-977f-51f70f019d21"/>
// </div><iframe id="welcome_message" style="position:fixed;z-index:100000000000;right:45px;border:none;width:300px;bottom: 80px;" src="https://widget.realtimechat.com/${id}/message"></iframe>
// <button id="rtc-button--close" onclick="rtcTogglePane()"> <span></span> </button> <div id="rtc-widget">
// <div style="position: absolute;right: 5px;background-color: white;padding: 5px;font-size: 16px;cursor: pointer;font-weight: bold;border: 1px solid black;top: 5px;" onclick="rtcTogglePane()">Close</div>
// <iframe src="https://widget.realtimechat.com/${id}" id="chats"></iframe> </div>
// <div id="rtc-mask" onclick="rtcTogglePane()"> </div>
// <style>.screen-reader-text{clip: rect(1px, 1px, 1px, 1px); position: absolute !important; height: 1px; width: 1px; overflow: hidden; word-wrap: normal !important;}
// .chat-welcome-message{position: absolute; width: 300px; padding: 10px; text-align: center;bottom: 100px; right: 140px; color: #9a9a9a; border: 1px solid lightgray; border-radius: 5px 5px 0; background: white;}
// .chat-welcome-message::after{content: ''; width: 20px; height: 20px; position: absolute; bottom: -6px; right: 18px; background: #fff; border-radius: 4px; z-index: -1;transform: rotate(45deg);}
// @-webkit-keyframes arrow-right-to-left{0%{margin-left: 20%;}96%{margin-left: 0;}100%{margin-left: 20%;}}
// #rtc-button--open,#rtc-button--open *,#rtc-button-close,#rtc-button-close *,#rtc-widget,#rtc-widget *{box-sizing: border-box;}
// #rtc-button--open,#rtc-mask{z-index: 2147483646;}#rtc-button--close,#rtc-widget{z-index: 2147483647;}
// #rtc-button--open,#rtc-button--open *,#rtc-button--close,#rtc-button--close *{border: 0; direction: ltr; font-family: Arial, sans-serif; font-size: 12px; 
//   font-weight: normal; letter-spacing: normal; opacity: 1; margin: 0; text-align: left; text-decoration: none; vertical-align: baseline; visibility: visible; 
//   height: auto; max-height: none; min-height: 0; width: auto; max-width: none; min-width: 0; text-transform: none; text-shadow: none;}
//   #welcome_message.visible{display: none;}#rtc-button--open.visible{display: none !important;}
//   #rtc-button--open{background: #fff; border: 0; border-radius: 5px 0 0 5px; box-shadow: 0 0 6px rgba(0, 0, 0, 0.25); height: 140px; 
//     font-family: Arial, sans-serif; font-size: 12px; line-height: normal; outline: 0; overflow: visible; padding: 0; position: fixed; bottom: 0; right: 20px; 
//     text-align: center; transform: translate(0, -50%); transition: all 0.2s ease-in-out; width: 140px;}#rtc-button--open img{max-width: 100%;}
//     #rtc-button--open:hover,#rtc-button--open:focus{box-shadow: 0 0 18px rgba(0, 0, 0, 0.25); cursor: pointer; transition: all 0.2s ease-in-out;}
//     #rtc-button--open img{max-width: 100%;}
//     #rtc-button--open__arrow{position: absolute; bottom: 0; left: 0; transform: translate(-24px, -50%); animation-name: arrow-right-to-left; 
//       animation-duration: 1.5s; animation-iteration-count: 3;}#rtc-button--open__count{background: #000; border-radius: 3px 0px 3px 3px; color: #fff; 
//         font-size: 11px; padding: 3px 5px; position: absolute; top: 2px; left: 2px;}#rtc-button--close{background: #000; border: 0; border-radius: 0; 
//           box-shadow: 0 0 6px rgba(0, 0, 0, 0.25); color: #fff; cursor: pointer; font-family: Arial, sans-serif; font-size: 12px; line-height: 32px; 
//           margin-right: -100%; outline: 0; padding: 0 0.8em; position: fixed; top: 0; right: 0; text-align: left; min-width: 256px; width: 33%;}
//           #rtc-button--close:hover,#rtc-button--close:focus{background: rgba(0, 0, 0, 0.8);}#rtc-button--close span{float: right; height: 100%; 
//             position: absolute; top: 0; right: 0; text-align: center; width: 17px;}#rtc-widget{-webkit-overflow-scrolling: touch; overflow-y: auto; 
//               border: 0; margin-right: -100%; position: fixed; bottom: 0px; right: 5px; border-radius: 10px; transition: all 0.2s ease-in-out; height: 730px; 
//               min-width: 256px; width: 33%;}#rtc-widget iframe{border: 0; height: 730px; width: 100%; position: absolute; right: 0; bottom: 0; left: 0;}
//               body.rtc-is-active{overflow-y: hidden !important;}#rtc-button--close.visible,#rtc-widget.visible{margin-right: 0; transition: all 0.2s ease-in-out;}
//               #rtc-mask{display: none;}#rtc-mask.visible{cursor: pointer; display: block; position: fixed; top: 0; right: 0; bottom: 0; left: 0;}
//               /*===RTC DISPLAY MOBILE RULES===*/@media only screen and (max-width: 768px){#rtc-button--open{height: 70px; width: 70px;}
//               #rtc-button--open__logo{width: 85%;}#rtc-button--open__arrow{transform: translate(-18px, -50%); width: 18px;}
//               #rtc-button--open__count{display: none;}#rtc-widget{min-width: 100vw;}#rtc-button--close{width: 80%; min-width: 256px;}}</style>
//               <script>function rtcTogglePane(){document.getElementById("rtc-widget").classList.toggle("visible"); document.getElementById("rtc-button--close").classList.toggle("visible"); document.getElementById("rtc-mask").classList.toggle("visible"); document.getElementById("rtc-button--open").classList.toggle("visible"); document.getElementById("welcome_message").style.display='none'; document.body.classList.toggle("rtc-is-active");}</script>`)

class Install extends Component {

  state = {
    bID: '',
    chatCode: '',
    isCopied: false
  }

  componentDidMount(){
    this.getData()
  };

  getData = () => {
    auth.onAuthStateChanged((user) => {
      if(user){
        db.ref(`users/${user.uid}/bID`)
        .once('value', (snap) => {
          let bID = localStorage.getItem('rtc_bid') || snap.val()[0];
          this.setState({ bID })
        })
        .then(() => {
          let code = chatCode(this.state.bID);
          this.setState({ chatCode: code })
        })
      }
    })
  }

  render(){

    const { classes, className, ...rest } = this.props;

    const { chatCode, isCopied } = this.state;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet
        {...rest}
        className={rootClassName}>
        <PortletHeader>
          <PortletLabel
            title="How to install your webchat"
          />
        </PortletHeader>
        <PortletContent>
          <List>
            <ListItem>- Copy the code from the button below</ListItem>
            <ListItem>- On Wordpress sites, paste the code within your header file</ListItem>
            <ListItem>- On HTML sites, paste the code right after the first {"<body>"} tag</ListItem>
            <ListItem>- Update / save and enjoy!</ListItem>
          </List>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <CopyToClipboard text={chatCode}
            onCopy={() => this.setState({ isCopied: true })}>
            <Button
              variant="contained"
              color={!isCopied ? "primary" : "secondary"}
              >
              {!isCopied ? "Copy" : "Copied!"}
            </Button>
          </CopyToClipboard>
        </PortletFooter>
      </Portlet>
    )
  }
}

export default withStyles(style)(Install)
