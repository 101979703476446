import React, { useState } from 'react';
import { Button,
         TextField,
         Dialog,
         DialogActions,
         DialogContent,
         DialogContentText,
         DialogTitle,
         Slide } from '@material-ui/core';

import DateTimePicker from './atoms';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, handleClose, startDate, handleSave }) {

  const [summary, setSummary] = useState('')

  const [newStartDate, setNewStartDate] = useState();
  const [endDate, setNewEndDate] = useState();

  const setStartDate = date => setNewStartDate(date);
  const setEndDate = date => setNewEndDate(date)

  const handleNewEvent = () => {
    handleSave({ newStartDate, endDate, summary })
  }

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Choose a date and time for your new event"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div>
              <TextField
                fullWidth
                variant="outlined"
                label="Event title"
                placeholder="Enter title here.."
                onChange={(e) => setSummary(e.target.value)}
                />
            </div>
            <DateTimePicker
              startDate={startDate}
              saveStart={setStartDate}
              saveEnd={setEndDate}
              />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={!summary}
            onClick={handleNewEvent}
            color="primary">
             Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
