import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import { Calendar, Views } from 'react-big-calendar';
import DatePicker from './DatePicker';
import { db } from '../../../database/firebase';
import axios from 'axios';
import moment from 'moment';
import localizer from 'react-big-calendar/lib/localizers/moment';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import TokenModal from './TokenModal';
// import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import './calendarStyles.css';

const localTime = localizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

const styles = theme => ({
  root: {
    padding: theme.spacing(4),
    height: '80vh'
  }
})

class CompanyCalendar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      calendarData: [],
      open: false,
      noCalToken: false
    }

    this.moveEvent = this.moveEvent.bind(this)
    this.newEvent = this.newEvent.bind(this)
  }

  componentDidMount(){
    this.getToken()
  }

  getToken = () => {
    let uid = localStorage.getItem('uid');
    if(uid){
      db.ref(`users/${uid}/bID`)
      .once('value', (snap) => this.setState({ bID: snap.val()[0] }))
      .then(() => {
        db.ref(`companies/${this.state.bID}`)
        .once('value', (snap) => {
          let token = snap.val().calToken;
          let calID = snap.val().calID;

          if(token){
            this.setState({ token, calID }, () => {
              this.getData()
            })
          } else {
            this.setState({ noCalToken: true })
          }
        })
      })
    }
  }

  getData = () => {
    axios({
      method: "GET",
      url: `https://www.googleapis.com/calendar/v3/calendars/${this.state.calID}/events`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: ` Bearer ${this.state.token}`
      }
    })
    .then(({ data }) => {
      let calendarData = []
      data.items.map((x) => {
        let id = x.id;
        let start = new Date(x.start.dateTime);
        let end = new Date(x.end.dateTime);

        let title = x.summary;
        return calendarData.push({ id, start, end, title })
      })
      this.setState({ calendarData });
    })
    .catch((e) => {
      this.getRefresh()
    })
  }

  getRefresh = () => {
    console.log('going to refresh');
    db.ref(`companies/${this.state.bID}/refreshToken`)
    .once('value', (snap) => {
      let token = snap.val();
      axios({
        method: "POST",
        url: `https://accounts.google.com/o/oauth2/token?refresh_token=${token}&grant_type=refresh_token&client_id=399736727366-q8ivdm9de96kdrco927vqr0ur1dh2h45.apps.googleusercontent.com&client_secret=1W7BhYEmD_ebS0yDXDNIzmzz`
      })
      .then(({ data }) => {
        db.ref(`companies/${this.state.bID}/calToken`)
        .set(data.access_token)
        .then(() => this.setState({ token: data.access_token }, () => {
          this.getData()
        }))
      })
      .catch((e) => {
        console.log(e);
      })
    })

  }

  moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    const { calendarData } = this.state

    const idx = calendarData.indexOf(event)
    let allDay = event.allDay

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false
    }

    const updatedEvent = { ...event, start, end, allDay }

    const nextEvents = [...calendarData]
    nextEvents.splice(idx, 1, updatedEvent)

    this.setState({
      calendarData: nextEvents,
    })
  }

  resizeEvent = ({ event, start, end }) => {
    const { calendarData } = this.state

    const nextEvents = calendarData.map(existingEvent => {
      return existingEvent.id === event.id
        ? { ...existingEvent, start, end }
        : existingEvent
    })

    this.setState({
      calendarData: nextEvents,
    })
  }

  newEvent = (e) => {
    e.action === 'doubleClick' &&
    this.setState({ startDate: e.start, open: true });
  }

  handleSave = ({ newStartDate, endDate, summary }) => {

    let { token } = this.state;

    let start = newStartDate ?
                new Date(newStartDate).toISOString() :
                new Date(this.state.startDate).toISOString();

    let end = new Date(endDate).toISOString();

    axios({
      method: "POST",
      url: `https://www.googleapis.com/calendar/v3/calendars/${this.state.calID}/events`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: ` Bearer ${token}`
      },
      data: {
        end: { "dateTime": end },
        start: { "dateTime": start },
        summary
      }
    })
    .then((res) => {
      this.setState({ open: false, calendarData: [], startDate: '' },
      () => {
        this.getData()
      })
    })
    .catch((e) => {
      console.log(e);
    })
  }

  render() {

    const { classes } = this.props;

    const { calendarData, open, startDate, noCalToken } = this.state;

    return (
      <div className={classes.root}>
        <DragAndDropCalendar
          selectable
          resizable
          localizer={localTime}
          events={calendarData}
          onEventDrop={this.moveEvent}
          onEventResize={this.resizeEvent}
          onSelectEvent={(e) => console.log(e)}
          onSelectSlot={this.newEvent}
          onDragStart={(e) => console.log(e)}
          defaultView={Views.MONTH}
        />
      <DatePicker
        open={open}
        startDate={startDate}
        handleSave={this.handleSave}
        handleClose={() => this.setState({ open: false })}
        />
      <TokenModal
        open={noCalToken}
        bID={this.state.bID}
        handleClose={() => this.setState({ noCalToken: false })}
        />
      </div>
    )
  }
}

export default withStyles(styles)(CompanyCalendar)
